import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { MainInformationFileBlock } from "./mainInformationFileBlock";
import { FileOpener } from "../file-opener/fileOpener";
import { setMapFilterState } from "../../../../../actions/control/setMapFilterState";
import { enableNotifications } from "../../../../../actions/api-actions";
import RefundForm from "../../../main/refundForm/refundForm";
import Chat from "../../../main/chat";
import "./mainInformation.scss";
import { TokenService } from "../../../../../service/token/storage.service";
import { AppLoadOffer } from "../../../modals/appLoadOffer";
import InsuranceCard from "../../../../common/InsuranceCard";
import AppoinDoctor from "../../../main/appoinDoctorForm/appoinDoctor";
import carImage from "../../../../../assets/img/carImage.png";
import planeImage from "../../../../../assets/img/planeImage.png";
import greenCard from "../../../../../assets/img/greenCard.png";
import { setIsOpenCarPage } from "../../../../../actions/control/setIsOpenCarPage";
import { setIsOpenPlanePage } from "../../../../../actions/control/setIsOpenPlainPage";
import { setIsOpenGreenCardPage } from "../../../../../actions/control/setIsOpenGreenCard";
import ModalWindow from "../modal/ModalWindow";
import NotificationPage from "../../../main/notification/notificationPage";
import { IoChevronForwardSharp } from "react-icons/io5";
import NotificationImg from "../../../../../../src/assets/img/svg/notification.svg";
import ClearNotification from "../../../../../../src/assets/img/svg/clear-notification.svg";

const CHAT_OPEN_PARAMS_NAME = "chat-open";
const LAST_OPENED_DATE_KEY = "lastOpenedDate";
const NOTIFICATION_PARAMS_NAME = "news-open";

let fileUrl = null;

export function MainInformation({
  insuranceInformation,
  broker,
  user,
  setMapFilter,
  allowNotifications,
  setOpenCalculatorPage,
  showToast,
}) {
  const [isRefundFormOpen, setIsRefundFormOpen] = useState(false);
  const [isAppointmentDoctorOpen, setSsAppointmentDoctorOpen] = useState(false);
  const [isNewMessage, setIsNewMessage] = useState(false);
  const [isFileOpen, setIsFileOpen] = useState(false);
  const [isCanCreateDoctor, setIsCanCreateDoctor] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [isAppLoadOfferOpen, setIsAppLoadOfferOpen] = useState(
    TokenService.getFirstAppRun() === null ||
      TokenService.getFirstAppRun() === undefined
  );
  const [isInsuranceCardOpen, setIsInsuranceCardOpen] = useState(false);

  const { chat = false } = useParams();

  const [isChatOpen, setIsChatOpen] = useState(chat === CHAT_OPEN_PARAMS_NAME);
  const [notificationOpen, setNotificationOpen] = useState(
    chat === NOTIFICATION_PARAMS_NAME
  );

  const history = useHistory();

  const onClickInsuranceCardHandler = () => {
    setIsInsuranceCardOpen(!isInsuranceCardOpen);
  };

  const onCloseAppLoadPopapHandler = () => {
    setIsAppLoadOfferOpen(false);
    TokenService.setFirstAppRun();
  };

  const onRefundFormOpenHandle = () => {
    setIsRefundFormOpen(true);
    setMapFilter(true);
  };

  const onOpenAppointmentDoctor = () => {
    if (emailCompany) {
      setSsAppointmentDoctorOpen(true);
      setMapFilter(true);
    } else {
      console.log(1);
      setIsCanCreateDoctor(!isCanCreateDoctor);
    }
  };

  const onCloseAppointymentDoctor = () => {
    setSsAppointmentDoctorOpen(false);
    setMapFilter(false);
  };

  const onRefundFormCloseHandle = () => {
    setIsRefundFormOpen(false);
    setMapFilter(false);
  };

  const openChat = (evt) => {
    evt.preventDefault();
    setIsChatOpen(true);
    setMapFilter(true);
    setIsNewMessage(false);
  };

  const closeChat = () => {
    setIsChatOpen(false);
    setMapFilter(false);
    if (chat === CHAT_OPEN_PARAMS_NAME) {
      history.push("/dashboard");
    }
  };

  const setNewMessageMobile = () => {
    if (!isChatOpen) {
      setIsNewMessage(true);
    }
  };

  if (!insuranceInformation) {
    insuranceInformation = {
      project: "",
      insurer: "",
      insurance_program: "",
      insurance_company: "",
      active_contract_from: "",
      active_contract_until: "",
    };
  }

  const {
    project,
    insurer,
    insurance_program,
    insurance_company,
    active_contract_from,
    active_contract_until,
    phoneCompany,
    additionalPhonesCompany,
    emailCompany,
  } = insuranceInformation;

  const { username, image, files, sharedFiles, polis, polis_vzk, newsletters } =
    user;

  console.log({ user });

  const onClickFileHandler = (url) => {
    setIsFileOpen(true);
    setMapFilter(true);
    fileUrl = url;
  };

  const onCloseFileOpener = () => {
    setIsFileOpen(false);
    setMapFilter(false);
  };

  const onOpenOtherPage = (ev, value) => {
    ev.preventDefault();
    setOpenCalculatorPage(value);
  };

  const openNotificationPage = (ev) => {
    ev.preventDefault();
    setNotificationOpen(!notificationOpen);
    if (notificationOpen) {
      setMapFilter(false);
    } else {
      setMapFilter(true);
    }
  };

  const newNotificationCounter = (notification) => {
    let newItem = notification.filter((item) => !item.readed);
    return newItem.length;
  };

  const isShowButtons = () => {
    if (!JSON.parse(additionalPhonesCompany).length) {
      window.location.href = `tel:${phoneCompany}`;
      return;
    }
    setShowButtons(!showButtons);
    const elements = document.querySelectorAll(".list-item");
    if (!showButtons) {
      elements.forEach((element, i) => {
        setTimeout(() => {
          element.classList.add("show");
        }, i * 100);
      });
    } else {
      elements.forEach((element, i) => {
        setTimeout(() => {
          element.classList.remove("show");
        }, (elements.length - 1 - i) * 100);
      });
    }
  };

  const shouldOpenBlock = () => {
    const lastOpenedDate = localStorage.getItem(LAST_OPENED_DATE_KEY);
    const currentDate = new Date();

    if (!lastOpenedDate) {
      return true;
    }

    const daysSinceLastOpened = Math.floor(
      (currentDate - new Date(lastOpenedDate)) / (1000 * 60 * 60 * 24)
    );
    console.log({ daysSinceLastOpened });
    return daysSinceLastOpened >= 3;
  };

  useEffect(() => {
    if (shouldOpenBlock()) {
      setIsOpenModal(true);
      localStorage.setItem(LAST_OPENED_DATE_KEY, new Date().toString());
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (TokenService.isNotificationsAllowed()) {
        allowNotifications();
      }
    }, 3000);
  }, []);

  useEffect(() => {
    if (chat === CHAT_OPEN_PARAMS_NAME) {
      setMapFilter(true);
    }
  }, [chat]);

  if (isFileOpen) {
    return <FileOpener fileUrl={fileUrl} onClose={() => onCloseFileOpener()} />;
  }

  return (
    <>
      {isOpenModal && (
        <ModalWindow isCloseModal={() => setIsOpenModal(false)} />
      )}

      {isAppLoadOfferOpen && (
        <AppLoadOffer
          isMobile={true}
          onClose={() => onCloseAppLoadPopapHandler()}
        />
      )}
      {!isRefundFormOpen &&
        !isAppointmentDoctorOpen &&
        !isChatOpen &&
        !notificationOpen && (
          <div className="main-information-mobile">
            <section className="main-information-mobile__user">
              <div className="personal-info__mobile-photo">
                <img src={image} alt="user" />
              </div>
              <div className="personal-info__mobile-name">{username}</div>
              {broker?.phone && (
                <a
                  href={`tel:${broker?.phone}`}
                  className="main-information-mobile__call-button"
                >
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.98026 5.75516C5.6162 7.07969 6.48312 8.32109 7.58101 9.41899C8.67891 10.5169 9.92031 11.3838 11.2448 12.0197C11.3588 12.0744 11.4157 12.1018 11.4878 12.1228C11.744 12.1975 12.0585 12.1438 12.2755 11.9885C12.3365 11.9448 12.3888 11.8926 12.4932 11.7881C12.8126 11.4687 12.9724 11.309 13.133 11.2045C13.7387 10.8107 14.5195 10.8107 15.1252 11.2045C15.2858 11.309 15.4455 11.4687 15.765 11.7881L15.943 11.9662C16.4286 12.4518 16.6714 12.6945 16.8033 12.9553C17.0656 13.4739 17.0656 14.0863 16.8033 14.6049C16.6714 14.8657 16.4286 15.1084 15.943 15.594L15.799 15.7381C15.3151 16.222 15.0731 16.4639 14.7441 16.6487C14.3791 16.8538 13.8121 17.0012 13.3935 17C13.0162 16.9989 12.7583 16.9257 12.2425 16.7793C9.4709 15.9926 6.85553 14.5083 4.6736 12.3264C2.49168 10.1445 1.00738 7.52911 0.220705 4.75746C0.0743222 4.24172 0.00113002 3.98385 7.83836e-06 3.60653C-0.00123819 3.18785 0.1462 2.6209 0.351255 2.25587C0.536051 1.92691 0.778012 1.68494 1.26193 1.20102L1.40597 1.05699C1.89155 0.571406 2.13434 0.328613 2.3951 0.196724C2.91369 -0.0655747 3.52611 -0.0655747 4.0447 0.196724C4.30546 0.328613 4.54825 0.571405 5.03383 1.05699L5.21189 1.23504C5.53133 1.55448 5.69104 1.7142 5.79547 1.87481C6.18927 2.4805 6.18927 3.26134 5.79547 3.86703C5.69105 4.02764 5.53133 4.18736 5.21189 4.5068C5.10744 4.61125 5.05521 4.66347 5.0115 4.72452C4.85616 4.94146 4.80252 5.25601 4.8772 5.51218C4.89821 5.58426 4.92556 5.64123 4.98026 5.75516Z"
                      fill="#519025"
                    />
                  </svg>
                </a>
              )}
              <button
                onClick={(evt) => openChat(evt)}
                className={`main-information-mobile__chat-button ${
                  isNewMessage
                    ? "main-information-mobile__chat-button--new-message"
                    : ""
                }`}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.6 0H2.4C1.04 0 0 1.04 0 2.4V15.2C0 15.52 0.16 15.84 0.48 15.92C0.56 16 0.72 16 0.8 16C1.04 16 1.2 15.92 1.36 15.76L4.32 12.8H13.6C14.96 12.8 16 11.76 16 10.4V2.4C16 1.04 14.96 0 13.6 0Z"
                    fill="#519025"
                  />
                </svg>
                Чат з брокером
              </button>
            </section>
            <section className="main-information-mobile__information-block">
              <div className="telephone-buttons-block">
                <button
                  className={`main-information-call-to-insurance ${
                    showButtons ? "open" : ""
                  }`}
                  onClick={() => isShowButtons()}
                >
                  Страховий випадок. Зв'язок із страховою
                </button>
                <a
                  href={`tel:${phoneCompany}`}
                  className={`main-information-call-to-insurance list-item`}
                >
                  +38{phoneCompany}
                  <svg
                    className="main-information-call-to-insurance-icon"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.98026 5.75516C5.6162 7.07969 6.48312 8.32109 7.58101 9.41899C8.67891 10.5169 9.92031 11.3838 11.2448 12.0197C11.3588 12.0744 11.4157 12.1018 11.4878 12.1228C11.744 12.1975 12.0585 12.1438 12.2755 11.9885C12.3365 11.9448 12.3888 11.8926 12.4932 11.7881C12.8126 11.4687 12.9724 11.309 13.133 11.2045C13.7387 10.8107 14.5195 10.8107 15.1252 11.2045C15.2858 11.309 15.4455 11.4687 15.765 11.7881L15.943 11.9662C16.4286 12.4518 16.6714 12.6945 16.8033 12.9553C17.0656 13.4739 17.0656 14.0863 16.8033 14.6049C16.6714 14.8657 16.4286 15.1084 15.943 15.594L15.799 15.7381C15.3151 16.222 15.0731 16.4639 14.7441 16.6487C14.3791 16.8538 13.8121 17.0012 13.3935 17C13.0162 16.9989 12.7583 16.9257 12.2425 16.7793C9.4709 15.9926 6.85553 14.5083 4.6736 12.3264C2.49168 10.1445 1.00738 7.52911 0.220705 4.75746C0.0743222 4.24172 0.00113002 3.98385 7.83836e-06 3.60653C-0.00123819 3.18785 0.1462 2.6209 0.351255 2.25587C0.536051 1.92691 0.778012 1.68494 1.26193 1.20102L1.40597 1.05699C1.89155 0.571406 2.13434 0.328613 2.3951 0.196724C2.91369 -0.0655747 3.52611 -0.0655747 4.0447 0.196724C4.30546 0.328613 4.54825 0.571405 5.03383 1.05699L5.21189 1.23504C5.53133 1.55448 5.69104 1.7142 5.79547 1.87481C6.18927 2.4805 6.18927 3.26134 5.79547 3.86703C5.69105 4.02764 5.53133 4.18736 5.21189 4.5068C5.10744 4.61125 5.05521 4.66347 5.0115 4.72452C4.85616 4.94146 4.80252 5.25601 4.8772 5.51218C4.89821 5.58426 4.92556 5.64123 4.98026 5.75516Z"
                      fill="#fff"
                    />
                  </svg>
                </a>
                {additionalPhonesCompany &&
                  JSON.parse(additionalPhonesCompany).map((el) => {
                    return (
                      <a
                        href={`tel:${el}`}
                        className={`main-information-call-to-insurance list-item`}
                      >
                        +38{el}
                        <svg
                          className="main-information-call-to-insurance-icon"
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.98026 5.75516C5.6162 7.07969 6.48312 8.32109 7.58101 9.41899C8.67891 10.5169 9.92031 11.3838 11.2448 12.0197C11.3588 12.0744 11.4157 12.1018 11.4878 12.1228C11.744 12.1975 12.0585 12.1438 12.2755 11.9885C12.3365 11.9448 12.3888 11.8926 12.4932 11.7881C12.8126 11.4687 12.9724 11.309 13.133 11.2045C13.7387 10.8107 14.5195 10.8107 15.1252 11.2045C15.2858 11.309 15.4455 11.4687 15.765 11.7881L15.943 11.9662C16.4286 12.4518 16.6714 12.6945 16.8033 12.9553C17.0656 13.4739 17.0656 14.0863 16.8033 14.6049C16.6714 14.8657 16.4286 15.1084 15.943 15.594L15.799 15.7381C15.3151 16.222 15.0731 16.4639 14.7441 16.6487C14.3791 16.8538 13.8121 17.0012 13.3935 17C13.0162 16.9989 12.7583 16.9257 12.2425 16.7793C9.4709 15.9926 6.85553 14.5083 4.6736 12.3264C2.49168 10.1445 1.00738 7.52911 0.220705 4.75746C0.0743222 4.24172 0.00113002 3.98385 7.83836e-06 3.60653C-0.00123819 3.18785 0.1462 2.6209 0.351255 2.25587C0.536051 1.92691 0.778012 1.68494 1.26193 1.20102L1.40597 1.05699C1.89155 0.571406 2.13434 0.328613 2.3951 0.196724C2.91369 -0.0655747 3.52611 -0.0655747 4.0447 0.196724C4.30546 0.328613 4.54825 0.571405 5.03383 1.05699L5.21189 1.23504C5.53133 1.55448 5.69104 1.7142 5.79547 1.87481C6.18927 2.4805 6.18927 3.26134 5.79547 3.86703C5.69105 4.02764 5.53133 4.18736 5.21189 4.5068C5.10744 4.61125 5.05521 4.66347 5.0115 4.72452C4.85616 4.94146 4.80252 5.25601 4.8772 5.51218C4.89821 5.58426 4.92556 5.64123 4.98026 5.75516Z"
                            fill="#fff"
                          />
                        </svg>
                      </a>
                    );
                  })}
              </div>
              <div
                className={`main-information-mobile-client-card__container ${
                  isInsuranceCardOpen
                    ? "main-information-mobile-client-card__container--open"
                    : ""
                }`}
              >
                <InsuranceCard
                  onClickHandler={() => onClickInsuranceCardHandler()}
                  isRotate={isInsuranceCardOpen}
                  isDesckTop={false}
                />
              </div>

              <div className="calculator-main-page-container">
                <button
                  className="calculator-main-page-link"
                  onClick={(event) => openNotificationPage(event)}
                >
                  {newsletters && newNotificationCounter(newsletters) ? (
                    <img
                      src={NotificationImg}
                      className="calculator-main-page-icon"
                      alt=""
                    />
                  ) : (
                    <img
                      src={ClearNotification}
                      className="calculator-main-page-icon"
                      alt=""
                    />
                  )}
                  Новини та пропозиції
                  {newsletters && newNotificationCounter(newsletters) ? (
                    <span className="calculator-main-page-counter">
                      {newNotificationCounter(newsletters)}
                    </span>
                  ) : (
                    <></>
                  )}
                  <IoChevronForwardSharp className="calculator-main-page-chevron-icon" />
                </button>
                <button
                  onClick={(evt) => onOpenOtherPage(evt, "avto")}
                  className="calculator-main-page-link"
                >
                  <img
                    src={carImage}
                    alt=""
                    className="calculator-main-icons"
                  />
                  Автоцивілка та ДЦВ
                </button>
                <button
                  onClick={(evt) => onOpenOtherPage(evt, "tourist")}
                  className="calculator-main-page-link"
                >
                  <img
                    src={planeImage}
                    alt=""
                    className="calculator-main-icons"
                  />
                  Туристичне страхування
                </button>
                <button
                  onClick={(evt) => onOpenOtherPage(evt, "greenCard")}
                  className="calculator-main-page-link"
                >
                  <img
                    src={greenCard}
                    alt=""
                    className="calculator-main-icons"
                  />
                  Зелена карта
                </button>
              </div>

              <div className="main-information-mobile__information-block-head">
                <p className="main-information-mobile__information-block-head-name">
                  Програма страхування
                </p>
                <p className="main-information-mobile__information-block-head-information">
                  <span>Проект</span>
                  {project ? project : ""}
                </p>
              </div>
              <div className="main-information-mobile__information-block__item">
                <p className="main-information-mobile__information-block__item-name">
                  Страхувальник
                </p>
                <p className="main-information-mobile__information-block__item-information">
                  {insurer}
                </p>
              </div>
              <div className="main-information-mobile__information-block__item">
                <p className="main-information-mobile__information-block__item-name">
                  Менеджер
                </p>
                <p className="main-information-mobile__information-block__item-information">
                  {broker ? broker.name : ""}
                </p>
              </div>
              <div className="main-information-mobile__information-block__item">
                <p className="main-information-mobile__information-block__item-name">
                  Страхова програма
                </p>
                <p className="main-information-mobile__information-block__item-information">
                  {insurance_program}
                </p>
              </div>
              <div className="main-information-mobile__information-block__item">
                <p className="main-information-mobile__information-block__item-name">
                  Страхова компанія
                </p>
                <p className="main-information-mobile__information-block__item-information">
                  {insurance_company}
                </p>
              </div>
              <div className="main-information-mobile__information-block__item">
                <p className="main-information-mobile__information-block__item-name">
                  Дата початку страхування
                </p>
                <p className="main-information-mobile__information-block__item-information">
                  {active_contract_from}
                </p>
              </div>
              <div className="main-information-mobile__information-block__item">
                <p className="main-information-mobile__information-block__item-name">
                  Дата кінця страхування
                </p>
                <p className="main-information-mobile__information-block__item-information">
                  {active_contract_until}
                </p>
              </div>
              {polis_vzk && (
                <div className="main-information-mobile__information-block__item">
                  <p className="main-information-mobile__information-block__item-name">
                    Поліс виїжджаючих за кордон
                  </p>
                  <p className="main-information-mobile__information-block__item-information">
                    №{polis_vzk}
                  </p>
                </div>
              )}
              <a
                href={`tel:${phoneCompany}`}
                className="main-information-mobile__information-block__item"
              >
                <p className="main-information-mobile__information-block__item-name">
                  Телефон СК
                </p>
                <p className="main-information-mobile__information-block__item-information">
                  +38{phoneCompany}
                </p>
              </a>
              {additionalPhonesCompany &&
                JSON.parse(additionalPhonesCompany).map((el) => {
                  return (
                    <a
                      href={`tel:${el}`}
                      className="main-information-mobile__information-block__item"
                      key={el}
                    >
                      <p className="main-information-mobile__information-block__item-name">
                        Телефон СК
                      </p>
                      <p className="main-information-mobile__information-block__item-information">
                        +38{el}
                      </p>
                    </a>
                  );
                })}
            </section>
            <section className="main-information-mobile_files-block">
              <MainInformationFileBlock
                title="Загальні документи страхової програми"
                files={sharedFiles !== null ? sharedFiles : []}
                onClickFile={(url) => onClickFileHandler(url)}
              />
            </section>
            <section className="main-information-mobile_files-block">
              <MainInformationFileBlock
                title="Індивідуальні документи страхової програми"
                files={files}
                onClickFile={(url) => onClickFileHandler(url)}
              />
            </section>
            <section className="main-information-mobile__refund-info">
              <h3 className="main-information-mobile__person-title">
                Запис до лікаря
              </h3>
              {isCanCreateDoctor && (
                <div className="main-information-mobile-inform-container">
                  <p className="main-information-mobile-inform-text">
                    Даний функціонал не доступний для вашої Страхової Компанії
                  </p>
                </div>
              )}
              <button
                onClick={() => onOpenAppointmentDoctor()}
                className="appointment_whith_doctor"
              >
                Подати заявку
              </button>
            </section>
            <section className="main-information-mobile__refund-info">
              <h3 className="main-information-mobile__person-title">
                Подача заявки на відшкодування витрачених коштів
              </h3>
              <button
                onClick={() => onRefundFormOpenHandle()}
                className="refund_info__ticket-button"
              >
                Повернути кошти
              </button>
            </section>
          </div>
        )}
      {notificationOpen && (
        <NotificationPage
          onCLoseNotificationPage={(ev) => openNotificationPage(ev)}
        />
      )}
      {isRefundFormOpen && (
        <RefundForm
          isMobile={true}
          onRefundFormCloseHandle={() => onRefundFormCloseHandle()}
        />
      )}
      {isAppointmentDoctorOpen && (
        <AppoinDoctor
          isMobile={true}
          onBack={() => onCloseAppointymentDoctor()}
        />
      )}
      <Chat
        isMobile={true}
        closeChatMobile={() => closeChat()}
        chatClass={"active"}
        showToast={showToast}
        isChatMobileOpen={isChatOpen}
        setNewMessageMobile={() => setNewMessageMobile()}
      />
      {/* {isChatOpen && (
        <Chat
          isMobile={true}
          closeChatMobile={() => closeChat()}
          chatClass={"active"}
        />
      )} */}
    </>
  );
}

const mapStateToProps = (state) => ({
  insuranceInformation: state.user.userData.insurance_information,
  broker: state.user.userData.broker,
  user: state.user.userData,
});

const mapDispatchToProps = (dispatch) => ({
  setOpenCalculatorPage(value) {
    if (value === "avto") {
      dispatch(setIsOpenCarPage(true));
    }
    if (value === "tourist") {
      dispatch(setIsOpenPlanePage(true));
    }
    if (value === "greenCard") {
      dispatch(setIsOpenGreenCardPage(true));
    }
  },
  setMapFilter(value) {
    dispatch(setMapFilterState(value));
  },
  allowNotifications() {
    dispatch(enableNotifications());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MainInformation);
