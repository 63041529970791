import React, {Component} from "react";
import logo from '../../../assets/img/logo_color.png';
import connect from "react-redux/es/connect/connect";

class Footer extends Component {
  
  setSocialNetworks = size => {
    const { data } = this.props;

    if(!data.socialNetworks) return;

    const sc = data.socialNetworks;

    return sc.map((item, index) => {
        return (
        <a key={index} className="item" href={item.link}>
            {!size ? item.name : ''}
            <svg className={`svg-${item.icon}-dark-dims`}>
                <use xlinkHref={`#${item.icon}-dark`}/>
            </svg>
        </a>
        )
    })
  };

  setMenu = () => {
    const { data } = this.props;

    if(!data.header) return;

    const menu = JSON.parse(data.header.menu);

    return menu.map((item, index) => {
      const company = item.as === 'company' ? 'footer__wrap__naw__item__company' : '';

      if(!item.items) return false;

      return (
        <div key={index} className={`footer__wrap__naw__item ${company}`}>
            <div className="caption">
                {item.label}
            </div>
            {
              item.items.map((subItem, index) => {
                return (
                  <a key={index} className='item link__item__footer' href={subItem.url}>
                      {subItem.label}
                  </a>
                )
              })
            }
        </div>
      )
    })
  };

    render() {
      const { data } = this.props;
        return(
            <div className="container-l">
              { data.footer && 
                <div> 
                  <div className="footer">
                    <div className="footer__about">
                      <div className="footer__logo">
                          <img src={logo} alt="logo"/>
                      </div>
                      <div className="footer__wrap__naw__item footer__wrap__naw__item__social footer__wrap__naw__item__mobile">
                          {this.setSocialNetworks('mobile')}
                      </div>
                      <div className="footer__about__tel">
                          <a href={`tel:${data.footer.phone}`} className="tel">{data.footer.phone}</a>
                          <a href={`mailto:${data.footer.email}`} className="emeil">{data.footer.email}</a>
                      </div>
                      <div className="footer__about__location">
                          <span>{data.footer.address_code}<br/> {data.footer.address_location} <b>{data.footer.address_position}</b></span>
                      </div>
                    </div>
                    <div className="footer__wrap">
                      <div className="footer__wrap__naw">
                        {this.setMenu()}
                        <div className="footer__wrap__naw__item footer__wrap__naw__item__social">
                          <div className="caption">Соціальні мережі</div>
                            {this.setSocialNetworks()}
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className="footer__wrap__company">
                    <div className="caption">{data.footer.image_title}</div>
                    <div className="footer__wrap__company__img">
                          {
                            data.footer.footerImages.map((item, index) => {
                              return (
                                <img key={index} src={item.path} alt={`comp${index}`}/>
                              )
                            })
                          }
                      </div>
                  </div>
                  <div className="copy__text">
                    <p>{data.footer.down_text_1}</p>
                    <p>{data.footer.down_text_2}</p>
                  </div>
                </div>
              }
          </div>
        )
    }
};

function mapStateToProps(state) {
  return {
      data: state.layouts,
  }
}

export default connect(mapStateToProps)(Footer);