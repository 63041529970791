import React, { useState, useMemo, useCallback, useEffect } from "react";
import './notificationPage.scss'
import { connect } from "react-redux";
import { getRefundTickets } from "../../../../actions/api-actions";
import { readNotification } from "../../../../actions/user/readNotification";
import NotificationInfoPage from "./notificationInfoPage";
import { RiLoader4Fill } from "react-icons/ri";
import { trackPromise } from "react-promise-tracker";
import ApiService from "../../../../service/api/api.service";
import emptyListImage from "../../../../assets/img/empty-list-image.png";
import { TokenService } from "../../../../service/token/storage.service";

export function NotificationPage({
  user,
  setReadNotification,
  onCLoseNotificationPage,
}) {
const [notificationList, setNotificationList] = useState([])
const [isOpenNotificationInfoPage, setIsOpenNotificationInfoPage] = useState(false)
const [activeNotificationPage, setActiveNotificationPage] = useState(null)
const [startIndex, setStartIndex] = useState(10);
const [isShowLoading, setisShowLoading] = useState(true)
const [isEmptyNews, setIsEmptyNews] = useState(false)

const { newsletters } = user;

const groupedData = useMemo(() => {
    const grouped = {};
    for (const item of notificationList) {
      const dateKey = item.date_public.split(' ')[0];
      if (!grouped[dateKey]) {
        grouped[dateKey] = [];
      }
      grouped[dateKey].push(item);
    }
    return grouped;
  }, [notificationList]);

  const getLabelForDate = useCallback((dateKey) => {
    const date = new Date(dateKey);
    if (isToday(date)) {
    return 'Сьогодні';
  } else if (isYesterday(date)) {
    return 'Вчора';
  } else {
    return date.toLocaleDateString();
  }
  },[notificationList])

const isToday = (date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

const isYesterday = (date) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  );
}

const newNotificationCounter = (notification) => {
  let newItem = notification.filter(item => !item.readed)
  return newItem.length
}

  const onClickBackButtonHandler = (evt) => {
    evt.preventDefault();
    if (isOpenNotificationInfoPage) {
        onToogleInfoPage(evt)
    } else {
        onCLoseNotificationPage(evt);
    }
  };

  const addNewNotifications = (evt) => {
    evt.preventDefault(); 
    setNotificationList([...notificationList, ...newsletters.slice(startIndex, startIndex + 10)]);
    setStartIndex(startIndex + 10);
  }

  const setShowTimeToDate = (str) => {
    const date = new Date(str);
    const formattedTime = date.toLocaleTimeString('uk-UA', { hour: 'numeric', minute: '2-digit' });
    return formattedTime
  }

  const onToogleInfoPage = (evt, notif) => {
    evt.preventDefault();
    if (isOpenNotificationInfoPage) {
        setActiveNotificationPage(null)
    } else {
        setActiveNotificationPage(notif)
        if (!notif.readed) {
          let data = {
            query: `mutation {
              newsletterRead(access_token: "${TokenService.getToken()}", entity_id: "${notif.id}")
            }`
          }
          setReadNotification(notif.id)
        trackPromise(
          ApiService.post("/cabinet", data)
            .then((response) => {
              console.log({response})
            })
            .catch((err) => console.error(err))
        );
        }
    }
    setIsOpenNotificationInfoPage(!isOpenNotificationInfoPage)
  }

  useEffect(() => {
    if (newsletters.length && newsletters.length > 10) {
        setNotificationList(newsletters.slice(0, 10));
        setIsEmptyNews(false)
        setisShowLoading(false)
    } else if (newsletters.length) {
        setNotificationList(newsletters);
        setIsEmptyNews(false)
        setisShowLoading(false)
    } else {
      setIsEmptyNews(true)
      setisShowLoading(false)
    }
  }, [newsletters]);


  return (
    <>
        <div className="refund-form--mobile">
          <div className="notification-page-header-wrapper">
            <button
              onClick={(evt) => onClickBackButtonHandler(evt)}
              className="back-button-mobile"
            >
              <svg
                width="27"
                height="17"
                viewBox="0 0 27 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 8.5L26 8.5"
                  stroke="#519025"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M1 8.50002L8.40592 1"
                  stroke="#519025"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M1 8.5L8.40592 16"
                  stroke="#519025"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <p className="notification-page-header-title">{ isOpenNotificationInfoPage ? `${activeNotificationPage?.title}` : 'Новини та пропозиції' }
            {!isOpenNotificationInfoPage && newNotificationCounter(notificationList) ? <span className="notification-page-header-counter">
            {newNotificationCounter(notificationList)}
            </span> : <></>}
            </p>
          </div>
          {!isOpenNotificationInfoPage && (
            <div className="notification-page-list">
              {isShowLoading && (<RiLoader4Fill className="notification-page-list-loader"/>)}
                {Object.keys(groupedData).map((dateKey) => {
                const items = groupedData[dateKey];
                const dateLabel = getLabelForDate(dateKey);
                return (
                <div key={dateKey} className="notification-page-date-block">
                    <h3 className="notification-page-date-title">{dateLabel}</h3>
                    <div className="notification-page-items-container">
                      {items.map((item) => (
                        <div 
                          className={`notification-page-item-block ${!item.readed && 'new-item'}`} 
                          onClick={(ev) => onToogleInfoPage(ev, item)} 
                          key={item.id}
                        >
                          <p 
                          className="notification-page-item-block-date"
                          >
                          {setShowTimeToDate(item.date_public)}
                          </p>
                          {item.image ? 
                          <img src={item.image} alt="" className="notification-page-item-block-img"/> : 
                          <div className="notification-page-item-block-img">{item.title[0]}</div>}
                          <div className="notification-page-item-info-block">
                          <p 
                          className="notification-page-item-block-title"
                          >
                          {item.title}
                          </p>
                          <p 
                          className="notification-page-item-block-description"
                          >
                          {item.message.length > 150 ? item.message?.substring(0, 150) + '...' : item.message}
                          </p>
                          </div>
                      </div>
                    ))}
                    </div>
                </div>
                );
            })}
            {isEmptyNews && <div className="notification-page-empty-image-container">
            <img
              className="notification-page-empty-image"
              src={emptyListImage}
              alt="empty-list"
            />
          </div>}
            {notificationList.length < newsletters.length ?
            <button 
            className="notification-page-watch-more-button"
            onClick={(ev) => addNewNotifications(ev)}
            >
              Показати ще
            </button> : <></>
            }
            </div>
          )}
          {isOpenNotificationInfoPage && (
            <NotificationInfoPage
                notification={activeNotificationPage}
            />
          )}
        </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.userData,
});


const mapDispatchToProps = (dispatch) => ({
  openNotificationPage(value) {
    dispatch(getRefundTickets());
  },
  setReadNotification(id) {
    console.log(id,123)
    dispatch(readNotification(id))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPage);
