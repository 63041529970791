import React from "react";
import "./visitList.scss";

export function PaginationBlock({
  paginationArray,
  onChangePageHandler,
  currentPage,
}) {
  const onPageButtonClick = (evt, pageNumber) => {
    evt.preventDefault();
    onChangePageHandler(pageNumber);
  };

  const onClickNextButtonHandler = (evt) => {
    evt.preventDefault();
    onChangePageHandler(currentPage + 1);
  };

  const onClickBackButtonHandler = (evt) => {
    evt.preventDefault();
    onChangePageHandler(currentPage - 1);
  };

  return (
    <div className="pagination-block">
      <button
        onClick={(evt) => onClickBackButtonHandler(evt)}
        className="pagination-block__next-button pagination-block__next-button--back"
        disabled={currentPage === 1}
      >
        <svg
          width="6"
          height="11"
          viewBox="0 0 6 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.775 9.225C6.075 9.525 6.075 9.975 5.775 10.275C5.625 10.425 5.475 10.5 5.25 10.5C5.025 10.5 4.875 10.425 4.725 10.275L0.225 5.775C-0.075 5.475 -0.075 5.025 0.225 4.725L4.725 0.225C5.025 -0.075 5.475 -0.075 5.775 0.225C6.075 0.525 6.075 0.975 5.775 1.275L1.8 5.25L5.775 9.225Z"
            fill="#C2C2C2"
          />
        </svg>
      </button>
      {paginationArray.map((item) => {
        return (
          <button
            onClick={(evt) => onPageButtonClick(evt, item)}
            className={`pagination-block__page-button ${
              item === currentPage
                ? "pagination-block__page-button--active"
                : ""
            }`}
            key={item}
          >
            {item}
          </button>
        );
      })}
      <button
        onClick={(evt) => onClickNextButtonHandler(evt)}
        className="pagination-block__next-button pagination-block__next-button--forvard"
        disabled={currentPage === paginationArray[paginationArray.length - 1]}
      >
        <svg
          width="6"
          height="11"
          viewBox="0 0 6 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.225 1.275C-0.0750006 0.975 -0.0750007 0.525001 0.224999 0.225001C0.374999 0.0750003 0.524999 4.7864e-07 0.749999 4.5897e-07C0.974999 4.39299e-07 1.125 0.0750002 1.275 0.225001L5.775 4.725C6.075 5.025 6.075 5.475 5.775 5.775L1.275 10.275C0.975 10.575 0.525 10.575 0.225 10.275C-0.0749999 9.975 -0.0749999 9.525 0.225 9.225L4.2 5.25L0.225 1.275Z"
            fill="#519025"
          />
        </svg>
      </button>
    </div>
  );
}
