import React, { Component } from "react";
import logo from "../../../assets/img/logo_color.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import connect from "react-redux/es/connect/connect";
import { withRouter, Redirect } from "react-router-dom";
import {
  TokenService,
  ProfileService,
  UserDataService,
} from "../../../service/token/storage.service";
import ApiService from "../../../service/api/api.service";

class MobileMenu extends Component {
  state = {
    openedMobNav: "",
    isLogout: false,
  };

  openNavMenu = (name) => {
    if (this.state.openedMobNav === name) {
      this.setState({ openedMobNav: "" });

      return false;
    }

    this.setState({ openedMobNav: name });
  };

  setMenu = () => {
    const { data } = this.props;
    const { openedMobNav } = this.state;

    if (!data.header) return;

    const menu = JSON.parse(data.header.menu);
    let isOpen = null;

    return menu.map((item, index) => {
      isOpen = openedMobNav === item.label ? "open" : "";

      return (
        <div
          key={index}
          className={`nav__link ${item.items ? isOpen : ""}`}
          onClick={item.items ? () => this.openNavMenu(item.label) : null}
        >
          {item.items && (
            <div className="nav__link--link">
              {item.label}
              {item.items && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className="uil uil-angle-down"
                  size="xs"
                />
              )}
            </div>
          )}
          {!item.items && <a href={item.url}>{item.label}</a>}
          {item.items && (
            <div className="nav__link__sub">
              {item.items.map((item, index) => {
                return (
                  <a key={index} href={item.url}>
                    {item.label}
                  </a>
                );
              })}
            </div>
          )}
        </div>
      );
    });
  };

  refreshPage = () => {
    window.location.reload(false);
  };

  loadMultiprofile = (profile) => {
    ProfileService.deleteMultiProfile(this.props.userData.id);
    TokenService.removeToken();
    ApiService.removeHeader();
    TokenService.saveToken(profile.token);
    ApiService.setHeader();

    this.refreshPage();

    return <Redirect to={`/dashboard`} />;
  };

  logout = () => {
    const multyProfiles = ProfileService.getMultyProfiles();
    const nextProfile = multyProfiles.find(
      (item) => item.id !== this.props.userData.id
    );
    if (nextProfile) {
      this.loadMultiprofile(nextProfile);
      return;
    }
    if (multyProfiles.length > 0) {
      ProfileService.deleteMultiProfile(this.props.userData.id);
    }
    TokenService.removeToken();
    TokenService.removePreviosToken();
    TokenService.removeChatData();
    ApiService.removeHeader();
    UserDataService.removeUserData();
    this.setState({ isLogout: true });
  };

  render() {
    const { open, closeMobileMenu, data } = this.props;
    if (this.state.isLogout) {
      return <Redirect to={`/login`} />;
    }
    return (
      <div className={`mibile__menu__wrap ${open}`}>
        <div className="mibile__menu__wrap__head">
          <div className="logo">
            <img className="logo__white" src={logo} alt="logo" />
          </div>
          <div className="btn__close" onClick={closeMobileMenu}>
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </div>
        <div className="mibile__menu">
          <div className="header__wrapper__nav">{this.setMenu()}</div>
          {data.header && (
            <div className="header__wrapper__about">
              <div className="header__wrapper__about__item will-w100">
                <button onClick={this.logout} className="button button__red">
                  Вийти
                </button>
              </div>
              <div className="header__wrapper__about__item">
                <a href={`tel:${data.header.phone}`}>{data.header.phone}</a>
              </div>
              <div className="header__wrapper__about__item">
                <a href={`mailto:${data.header.email}`}>{data.header.email}</a>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.layouts,
    userData: state.user.userData,
  };
}

export default connect(mapStateToProps)(withRouter(MobileMenu));
