import React, { Component } from "react";
import "./assets/css/main.css";
import Dashboard from "./components/dashboard/dashboard";
import { withRouter, Switch, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Trouble from "./components/dashboard/trouble";
import Login from "./components/auth/login";
import Register from "./components/auth/register";
import resetPassword from "./components/auth/resetPass";
import setPassword from "./components/auth/setPassword";
import PublicRoute from "./components/routes/PublicRoute";
import PrivateRoute from "./components/routes/PrivateRoute";
import ApiService from "./service/api/api.service";
import { TokenService } from "./service/token/storage.service";
import { Toast } from "./components/models/toast";
import { LoadScript } from "@react-google-maps/api";
// import firebase from "./firebase";
// import { onMessageListener } from "./firebase";
import firebase from "firebase";
import { isIos } from "./utils/utils";

const HOST_URL = "https://cabinet.tbt-broker.com/";

ApiService.init(process.env.REACT_APP_API_HOST);

if (TokenService.getToken()) {
  ApiService.setHeader();
}

console.log(navigator.userAgent);

console.log({ "is-safary": isIos() });

var firebaseConfig = {
  apiKey: "AIzaSyCeWI2Q-FLp2PIRYP6qZUH_Z57orFmnMPM",
  authDomain: "tbt---insurance-broker.firebaseapp.com",
  projectId: "tbt---insurance-broker",
  storageBucket: "tbt---insurance-broker.appspot.com",
  messagingSenderId: "161242853353",
  appId: "1:161242853353:web:a97f6d16310ac796d8e578",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// if (isIos()) {
//   //checking that the application is launching in native ios app
//   if (navigator.userAgent.indexOf("Safari") < 0) {
//     TokenService.setFirstAppRun();
//   }

//   let urlParameter = `${window.location.href}`;

//   console.log(urlParameter);

//   urlParameter = urlParameter.replace(HOST_URL, "");

//   console.log(urlParameter);

//   if (!TokenService.getFireBaseToken()) {
//     TokenService.setFireBaseToken(urlParameter ? urlParameter : "");
//   }
// }

class App extends Component {
  state = {
    tStatus: "",
    tContent: "",
    greeting: false,
  };

  showToast = (status, content) => {
    this.setState({
      tStatus: status,
      tContent: content,
    });

    setTimeout(() => {
      this.setState({
        tStatus: "",
      });
    }, 3000);
  };

  showGreeting = () => {
    this.setState({ greeting: true });

    setTimeout(() => {
      this.setState({ greeting: false });
    }, 4000);
  };

  componentDidMount = () => {
     if (!isIos()) {
      if (
        TokenService.getFireBaseToken() === "" ||
        TokenService.getFireBaseToken() === null
      ) {
        const msg = firebase.messaging();
        msg
          .requestPermission()
          .then(() => {
            return msg.getToken();
          })
          .then((data) => {
            TokenService.setFireBaseToken(data ? data : "");
            if (data !== "") {
              TokenService.enableNotification();
            }
            console.warn("token", data);
          })
          .catch((error) => {
            console.log(14,error.message)
          })
        //   onMessageListener()
        //     .then((payload) => {
        //       new Notification(payload.notification.body);
        //       console.log(payload);
        //     })
        //     .catch((err) => console.log("failed: ", err));
      }
   }
  };

  render() {
    const { location } = this.props;

    const { tStatus, tContent, greeting } = this.state;

    return (
      <div className="App">
        <LoadScript
          id="script-loader"
          googleMapsApiKey={process.env.REACT_APP_MAP_KEY}
          language="en"
          region="ukr"
        >
          <TransitionGroup className="transition-group">
            <CSSTransition
              key={location.key}
              timeout={{ enter: 600, exit: 600 }}
              classNames={"fade"}
            >
              <section className="route-section">
                <Switch location={location}>
                  <PublicRoute
                    showToast={this.showToast}
                    restricted={true}
                    exact
                    path="/register"
                    component={Register}
                  />
                  <PublicRoute
                    showToast={this.showToast}
                    showGreeting={this.showGreeting}
                    restricted={true}
                    exact
                    path="/login"
                    component={Login}
                  />
                  <PublicRoute
                    showToast={this.showToast}
                    restricted={true}
                    exact
                    path="/recovery"
                    component={resetPassword}
                  />
                  <PublicRoute
                    showToast={this.showToast}
                    showGreeting={this.showGreeting}
                    restricted={true}
                    exact
                    path="/password"
                    component={setPassword}
                  />
                  <PrivateRoute
                    showToast={this.showToast}
                    greeting={greeting}
                    exact
                    path="/dashboard/:chat?"
                    component={Dashboard}
                  />
                  <PrivateRoute exact path="/" component={Dashboard} />
                  <Redirect to="/login" />
                </Switch>
              </section>
            </CSSTransition>
          </TransitionGroup>
          <Toast status={tStatus} content={tContent} />
        </LoadScript>
      </div>
    );
  }
}

export default withRouter(App);
