import React from 'react';
import messageImg from '../../../assets/img/message.svg';

function ToggleChatBtn({ toggleChat, chatClass, chatBtnClass, disabledBtn }) {
    const isChat = chatClass === 'active' ? 'chat__opened' : '';

    const btnClick = () => {
        toggleChat();
        disabledBtn();
    }

    return (
        <div onClick={btnClick} className={`toggle__chat__btn ${isChat} ${chatBtnClass}`}>
            <img src={messageImg} alt="message"/>
        </div>
    )
}

export default ToggleChatBtn;