import React, { useRef, useState, useEffect } from "react";
import { getFileImage, getFileImageMobile } from "../../../../utils/utils";
import { FileValidationErrorScreen } from "./fileValidationErrorScreen";
import { isFileSiZeAllowed } from "../../../../utils/utils";
import { ITEM_DESCRIPTION } from "../../../../const/const";
import FileAtachImage from "../../../../assets/img/file-atach.svg";

const ERROR_MESSAGE =
  "Ви можете прикріпити до 3 файлів формату: (pdf/docx/xlsx/png/jpeg/jpg), розміром до 10 мб";
let isInputActive = false;
const OTHER_ERROR_MESSAGE = "Ви можете прикріпити до 10 файлів формату: (pdf/docx/xlsx/png/jpeg/jpg), розміром до 10 мб";

export function FileInputBlock({
  itemDescription,
  fileNumber,
  filesArray,
  onInputFileHandler,
  isMobile = false,
  onClickNextBlockHandler,
  otherFiles = false
}) {
  const [isValiationError, setIsValidationError] = useState(false);
  const fileInputRef = useRef();
console.log({otherFiles})
console.log({fileNumber})
  isInputActive = filesArray.length < fileNumber;

  let isNextButtonDissabled =
    itemDescription === ITEM_DESCRIPTION.REFUND_TICKET ||
    itemDescription === ITEM_DESCRIPTION.PERFOMED_ACT ||
    itemDescription === ITEM_DESCRIPTION.OTHER
      ? false
      : true;

  if (filesArray.length > 0) {
    isNextButtonDissabled = false;
  }

  const onChangeHandler = () => {
    const inputFilesArray = Array.from(fileInputRef.current.files);

    if (
      inputFilesArray.length + filesArray.length > fileNumber ||
      !isFileSiZeAllowed(inputFilesArray)
    ) {
      setIsValidationError(true);
      return;
    }

    if (filesArray.length > 0 || inputFilesArray.length > 0) {
      isNextButtonDissabled = false;
    }

    if (filesArray.length > 0) {
      onInputFileHandler(filesArray.concat(inputFilesArray));
      return;
    }

    onInputFileHandler(inputFilesArray);
  };

  const onDelateFile = (e, fileArrayIndex) => {
    e.preventDefault();
    filesArray.splice(fileArrayIndex, 1);
    onInputFileHandler(filesArray);
  };

  const onCloseErrorScreenHandler = () => {
    setIsValidationError(false);
  };

  const setMaximumFileNumbers = () => {
    if (fileNumber === 1) {
      return '1 файл'
    } else if (fileNumber === 3) {
      return 'до 3 файлів'
    } else if (fileNumber === 10) {
      return 'до 10 файлів'
    }
  }

const  fileLabelText = `Ви можете прикріпити ${
  setMaximumFileNumbers()
} формату: (pdf/docx/xlsx/png/jpeg/jpg), розміром до 10 мб`;

  return (
    <>
      {!isMobile && (
        <div className={`field-block ${isMobile ? "field-block--mobile" : ""}`}>
          <p className="field-block__item-description">{`${itemDescription}:`}</p>
          <div className="field-block__item">
            <p className="field-block__item-format-type">{fileLabelText}</p>
            <div
              className={
                isInputActive
                  ? "field-block__input"
                  : "field-block__input--inactive"
              }
            >
              <label className="refund-form__label">
                {isInputActive && (
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.6863 9.66814L10.6368 17.3186C9.39844 18.4956 7.80623 19 6.21402 19C4.62182 19 3.02961 18.4115 1.79123 17.3186C-0.597077 15.0487 -0.597077 11.2655 1.79123 8.99557L9.92917 1.26106C10.7253 0.504425 11.8752 0 13.0251 0C14.1751 0 15.325 0.420354 16.1211 1.26106C17.8902 2.94248 17.8902 5.54867 16.1211 7.23009L7.98314 14.9646C6.92167 15.9735 5.24101 15.9735 4.268 14.9646C3.82572 14.5442 3.56035 13.8717 3.56035 13.1991C3.56035 12.5265 3.82572 11.854 4.35645 11.4336L11.8752 4.28761C12.229 3.95133 12.7598 3.95133 13.1136 4.28761C13.4674 4.62389 13.4674 5.12832 13.1136 5.4646L5.59483 12.6106C5.41792 12.7788 5.32947 12.9469 5.32947 13.1991C5.32947 13.4513 5.41792 13.6195 5.59483 13.7876C5.94866 14.1239 6.47939 14.1239 6.83322 13.7876L14.9712 6.0531C16.0326 5.04425 16.0326 3.4469 14.9712 2.52212C13.9981 1.59734 12.229 1.59734 11.256 2.52212L3.11807 10.2566C1.34895 11.9381 1.34895 14.5442 3.11807 16.2257C4.88719 17.9071 7.62932 17.9071 9.39844 16.2257L17.5364 8.49115C17.8902 8.15487 18.4209 8.15487 18.7748 8.49115C19.1286 8.82743 19.0401 9.33186 18.6863 9.66814Z"
                      fill="#519025"
                    />
                  </svg>
                )}
                {!isInputActive && (
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.6863 9.66814L10.6368 17.3186C9.39844 18.4956 7.80623 19 6.21402 19C4.62182 19 3.02961 18.4115 1.79123 17.3186C-0.597077 15.0487 -0.597077 11.2655 1.79123 8.99557L9.92917 1.26106C10.7253 0.504425 11.8752 0 13.0251 0C14.1751 0 15.325 0.420354 16.1211 1.26106C17.8902 2.94248 17.8902 5.54867 16.1211 7.23009L7.98314 14.9646C6.92167 15.9735 5.24101 15.9735 4.268 14.9646C3.82572 14.5442 3.56035 13.8717 3.56035 13.1991C3.56035 12.5265 3.82572 11.854 4.35645 11.4336L11.8752 4.28761C12.229 3.95133 12.7598 3.95133 13.1136 4.28761C13.4674 4.62389 13.4674 5.12832 13.1136 5.4646L5.59483 12.6106C5.41792 12.7788 5.32947 12.9469 5.32947 13.1991C5.32947 13.4513 5.41792 13.6195 5.59483 13.7876C5.94866 14.1239 6.47939 14.1239 6.83322 13.7876L14.9712 6.0531C16.0326 5.04425 16.0326 3.4469 14.9712 2.52212C13.9981 1.59734 12.229 1.59734 11.256 2.52212L3.11807 10.2566C1.34895 11.9381 1.34895 14.5442 3.11807 16.2257C4.88719 17.9071 7.62932 17.9071 9.39844 16.2257L17.5364 8.49115C17.8902 8.15487 18.4209 8.15487 18.7748 8.49115C19.1286 8.82743 19.0401 9.33186 18.6863 9.66814Z"
                      fill="#9EA8AB"
                    />
                  </svg>
                )}

                <input
                  className="file-input"
                  ref={fileInputRef}
                  onChange={() => onChangeHandler()}
                  type="file"
                  accept=".pdf, .docx, .xlsx, .jpeg, .png, .jpg"
                  multiple={fileNumber > 1 ? true : false}
                  disabled={!isInputActive}
                  key={Date.now()}
                />
                <span>Прикріпити файл</span>
              </label>
            </div>
          </div>
          <ul className="file-block">
            {filesArray.length > 0 &&
              filesArray.map((item, index) => {
                return (
                  <li className="file-block__item" key={item.name}>
                    <img
                      src={getFileImage(item.name.split(".").pop())}
                      alt="file-type"
                    />
                    <span>{item.name}</span>
                    <button
                      onClick={(e) => onDelateFile(e, index)}
                      className="file-block__item-delate-button"
                    >
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.78571 8.78571C10.0714 9.07143 10.0714 9.5 9.78571 9.78571C9.64286 9.92857 9.5 10 9.28571 10C9.07143 10 8.92857 9.92857 8.78571 9.78571L5 6L1.21429 9.78571C1.07143 9.92857 0.928571 10 0.714286 10C0.5 10 0.357143 9.92857 0.214286 9.78571C-0.0714286 9.5 -0.0714286 9.07143 0.214286 8.78571L4 5L0.214286 1.21429C-0.0714286 0.928571 -0.0714286 0.5 0.214286 0.214286C0.5 -0.0714286 0.928571 -0.0714286 1.21429 0.214286L5 4L8.78571 0.214286C9.07143 -0.0714286 9.5 -0.0714286 9.78571 0.214286C10.0714 0.5 10.0714 0.928571 9.78571 1.21429L6 5L9.78571 8.78571Z"
                          fill="#4E4E4E"
                        />
                      </svg>
                    </button>
                  </li>
                );
              })}
          </ul>
          {isValiationError && (
            <FileValidationErrorScreen
              onCloseHandler={() => onCloseErrorScreenHandler()}
              errorMessage={fileLabelText}
            />
          )}
        </div>
      )}
      {isMobile && (
        <>
          <div className={`field-block--mobile`}>
            <p className="field-block__item-description--mobile">{`${itemDescription}:`}</p>
            <p className="field-block__item-format-type--mobile">
              {`Ви можете прикріпити ${
                 setMaximumFileNumbers()
              } формату: (pdf/docx/xlsx/png/jpeg/jpg)`}
            </p>
            <ul className="file-block--mobile" style={otherFiles ? {height: 250, overflow: 'auto'} : {}}>
              {filesArray.length > 0 &&
                filesArray.map((item, index) => {
                  return (
                    <li className="file-block__item--mobile" key={item.name}>
                      <img
                        src={getFileImageMobile(item.name.split(".").pop())}
                        alt="file-type"
                      />
                      <span>{item.name}</span>
                      <button
                        onClick={(e) => onDelateFile(e, index)}
                        className="file-block__item-delate-button--mobile"
                      >
                        <svg
                          width="14"
                          height="15"
                          viewBox="0 0 14 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13.3 2.72727H10.5V2.04545C10.5 0.886364 9.59 0 8.4 0H5.6C4.41 0 3.5 0.886364 3.5 2.04545V2.72727H0.7C0.28 2.72727 0 3 0 3.40909C0 3.81818 0.28 4.09091 0.7 4.09091H1.4V12.9545C1.4 14.1136 2.31 15 3.5 15H10.5C11.69 15 12.6 14.1136 12.6 12.9545V4.09091H13.3C13.72 4.09091 14 3.81818 14 3.40909C14 3 13.72 2.72727 13.3 2.72727ZM4.89973 2.04559C4.89973 1.6365 5.17973 1.36377 5.59973 1.36377H8.39973C8.81973 1.36377 9.09973 1.6365 9.09973 2.04559V2.72741H4.89973V2.04559ZM10.4998 13.6368C10.9198 13.6368 11.1998 13.364 11.1998 12.9549V4.09131H2.79977V12.9549C2.79977 13.364 3.07977 13.6368 3.49977 13.6368H10.4998ZM6.29973 6.81805V10.909C6.29973 11.318 6.01973 11.5908 5.59973 11.5908C5.17973 11.5908 4.89973 11.318 4.89973 10.909V6.81805C4.89973 6.40896 5.17973 6.13623 5.59973 6.13623C6.01973 6.13623 6.29973 6.40896 6.29973 6.81805ZM9.1 10.909V6.81805C9.1 6.40896 8.82 6.13623 8.4 6.13623C7.98 6.13623 7.7 6.40896 7.7 6.81805V10.909C7.7 11.318 7.98 11.5908 8.4 11.5908C8.82 11.5908 9.1 11.318 9.1 10.909Z"
                            fill="#EA5455"
                          />
                        </svg>
                      </button>
                    </li>
                  );
                })}
            </ul>
            <div
              className={` field-block__input--mobile
              ${
                isInputActive
                  ? "field-block__input"
                  : "field-block__input--inactive"
              }
            `}
            >
              <label className="refund-form__mobile-label">
                <input
                  className="file-input"
                  ref={fileInputRef}
                  onChange={() => onChangeHandler()}
                  type="file"
                  accept=".pdf, .docx, .xlsx, .jpeg, .png, .jpg"
                  multiple={fileNumber > 1 ? true : false}
                  disabled={filesArray.length === fileNumber}
                />
                <span>Прикріпити файл</span>
              </label>
              <button
                onClick={(evt) => onClickNextBlockHandler(evt)}
                className="refund-form__next-button"
                disabled={isNextButtonDissabled}
              >
                Наступний крок
              </button>
            </div>

            <div className="field-block__item field-block__item--mobile"></div>
          </div>
          {isValiationError && (
            <FileValidationErrorScreen
              onCloseHandler={() => onCloseErrorScreenHandler()}
              errorMessage={otherFiles ? OTHER_ERROR_MESSAGE : ERROR_MESSAGE}
            />
          )}
        </>
      )}
    </>
  );
}
