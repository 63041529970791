const initialState = {
  username: null,
  id: null,
  phone: null,
  birth_date: null,
  address: null,
  polis: null,
  card_number: null,
  image: null,
  lpu: null,
  messages: null,
  insuranse_information: null,
  broker: null,
  files: null,
  sharedFiles: null,
  isAddingProfile: false,
  previosToken: null,
  medicalVisits: [],
  newsletters: [],
  userLocation: {}
};

export const userData = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USER_DATA":
      return {
        ...state,
        ...action.payload,
      };
    case "UPDATE_USER_TEL":
      return state.phone !== action.payload.phone
        ? {
            ...action.payload.fields,
          }
        : state;
    case "ADD_MESSAGE":
      return {
        ...state,
        messages: [...state.messages, action.payload],
      }
    case "UPDATE_USER_TEL":
      return {
        ...state,
        isAddingProfile: action.payload,
      }
    case "ADD_PREVIOS_TOKEN":
      return {
        ...state,
        previosToken: action.payload,
      }
    case "MEDICAL_VISITS":
      return {
        ...state,
        medicalVisits: action.payload
      }
    case "USER_LOCATION":
      return {
        ...state,
        userLocation: action.payload
      }
    case "READ_NOTIFICATION":
      return {
        ...state,
        newsletters: [...state.newsletters.map(el => {
          if (el.id === action.payload) {
            return {
              ...el,
              readed: true
            }
          } else {
            return el
          }
        })]
      }
    default:
      return state;
  }
};
