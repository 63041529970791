import React from 'react';

export const Consult = ({openModalCall}) => {
    return (
        <div className="questions">
            <div className="caption-m caption">Услуги компании</div>
            <p className="desc">Мы с радостью ответим на них и поможем вам выбрать правильный продукт</p>
            <div 
                className="button button__success btn__request-call" 
                onClick={openModalCall}
            >
                Получить конслультацию
            </div>
        </div>
    )
}
