import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import logo from '../../assets/img/logo_color.png';
import ApiService from '../../service/api/api.service';
import { TokenService } from '../../service/token/storage.service';
import InputMask from 'react-input-mask';
import { trackPromise } from 'react-promise-tracker';
import { BackButton } from './backButton';
import './login.scss';

class ResetPass extends Component {
    state = {
        isFormError: false,
        card: '',
        sms: '',
        isSMSField: false,
        cardErrorText: '',
        smsErrorText: ''
    }

    handleInput = ({target}) => {
        this.setState({[target.name]: target.value})
    };

    sendSMSCode = () => {
        if(this.state.card.length !== 0) {
            let data =  {
                query: `query {
                        authResetPassword(card: "${this.state.card}") {
                            success,
                            message
                          }
                    }`
                };

                trackPromise(
                    ApiService.post('/graphql', data)
                    .then(response => {
                        const { data } = response.data;
                        const { showToast } = this.props;

                        if(data.authResetPassword.success) {
                            showToast('success', data.authResetPassword.message)
                            this.setState({isSMSField: true});
                        } else {
                            this.setState({
                                isFormError: true,
                                cardErrorText: data.authResetPassword.message,
                            })
                        }
                    })
                    .catch(err => console.error(err))
                );
        } else {
            this.setState({
                isFormError: true,
                cardErrorText: "Поле обов'язкове для заповнення"
            });
        }
    };

    toChangePass = () => {
        if(this.state.card.length !== 0 && this.state.sms.length !== 0) {
            let data =  {
                query: `query {
                        authResetPasswordSms(card: "${this.state.card}", sms: ${this.state.sms}) {
                            success,
                            message,
                            access_token
                          }
                    }`
                };

                trackPromise(
                    ApiService.post('/graphql', data)
                    .then(response => {
                        const { data } = response.data;

                        if(data.authResetPasswordSms.success) {
                            TokenService.saveTempToken(data.authResetPasswordSms.access_token);

                            this.props.history.push('/password');

                        } else {
                            this.setState({
                                isFormError: true,
                                cardErrorText: '',
                                smsErrorText: data.authResetPasswordSms.message,
                            })
                        }
                    })
                    .catch(err => console.error(err))
                );
        } else {
            this.setState({
                isFormError: true,
                cardErrorText: "Поле обов'язкове для заповнення",
                smsErrorText: "Поле обов'язкове для заповнення",
            })
        }
    };

    clearError = () => {
        this.setState({isFormError: false})
    };

    handleKeydown = (event) => {
        if(event.keyCode === 13) {
            this.toChangePass();
        }
    };

    render() {
        const { 
            isSMSField,
            isFormError,
            cardErrorText,
            smsErrorText
         } = this.state;

        return (
            <div className="check_in">
                <Link className="back-button" to="/login"><BackButton /></Link>
                <div className="check_in__logo">
                    <img src={logo} alt="logo"/>
                </div>
                <h1 className="caption">Відновлення паролю</h1>
                <p className="desc">Якщо ви забули пароль, введіть в поле нижче номер вашого полісу, після чого на ваш номер буде висланий новий пароль в SMS повідомленні</p>
                <form className={`check_in__form recovery__form ${isFormError ? 'error' : ''} `}>
                    <div className="check_in__form__row">
                        <label>Номер полісу:</label>
                        <InputMask
                            name="card"
                            type="card"
                            required 
                            onFocus={this.clearError} 
                            onChange={this.handleInput}
                        />
                        <div className="help__error">{cardErrorText}</div>
                    </div>
                    {
                        !isSMSField &&
                        <div 
                            onClick={this.sendSMSCode} 
                            type="button" 
                            className="button button__silver button__sms"
                        >
                            
                            Надіслати код в SMS повідомленні
                        </div>
                    }
                    {
                        isSMSField && 
                        <div className="fade-element">
                            <div className="check_in__form__row">
                                <label>Пароль з SMS повідомлення</label>
                                <input 
                                    name="sms" 
                                    type="password" 
                                    required 
                                    onChange={this.handleInput}
                                    onFocus={this.clearError}
                                    onKeyDown={this.handleKeydown}
                                />
                                <div className="help__error">{smsErrorText}</div>
                            </div>  
                            <div className="link__other">
                                <div className="link__other__row">
                                    <span className="pointer" onClick={this.sendSMSCode}>Відправити SMS повторно</span>
                                </div>
                            </div>
                            <button type="button" onClick={this.toChangePass} className="button button__success">Далі</button>
                        </div>
                    }

                </form>
            </div>
        )
    }
}

export default ResetPass;