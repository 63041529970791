import React, { useState, useRef, useEffect } from "react";
import connect from "react-redux/es/connect/connect";
import { setCurrentLpuList } from "../../../../actions/lpu/setCurrentLpuList";
import uniqid from "uniqid";
import emptyListImage from "../../../../assets/img/empty-list-image.png";
import { searchByAddressAndName, sortByDistance } from "../../../../utils/utils";

let searchValue;

function MobileSearchLpuList({
  onCanselClickHandle,
  lpu,
  setLpuCurrentSearchList,
  lpuCategories,
  onBackToMapClickHandle,
  userLocation
}) {
  const [currentLpu, setCurrentLpu] = useState([]);
  const {latitude, longitude} = userLocation
  const searchInput = useRef();

  const delateSearchInputText = (e) => {
    e.preventDefault();
    searchInput.current.value = "";
      let sorted = sortByDistance(lpu.slice(), latitude, longitude)
      setCurrentLpu(sorted)
  };

  const onChangeInputHandler = () => {
    searchValue = searchInput.current.value;

    if (searchValue !== "") {
      console.log(searchByAddressAndName(lpu, searchValue),1)
      setCurrentLpu(searchByAddressAndName(lpu, searchValue));
    }

    if (searchValue === "") {
      let sorted = sortByDistance(lpu.slice(), latitude, longitude)
      setCurrentLpu(sorted)
    }
  };

  const onClickItemHendler = (value) => {
    const lpuList = [value];
    setLpuCurrentSearchList(lpuList);
    onBackToMapClickHandle();
  };

  const onCanselClick = () => {
    onCanselClickHandle();
  };

  const enterSubmitButton = (event) => {
    event.preventDefault();
  }

  useEffect(() => {
    if (lpu.length) {
      let sorted = sortByDistance(lpu.slice(), latitude, longitude)
      setCurrentLpu(sorted)
    }
  }, [lpu])

  useEffect(() => {
    searchInput.current.focus();
  }, [])

  return (
    <>
      <div className={`search-mobile-screen`}>
        <form className="form-mobile" onSubmit={enterSubmitButton}>
          <input
            ref={searchInput}
            onChange={() => onChangeInputHandler()}
            className="form-mobile__input"
            type="text"
            id="serch-lpu"
          />
          <button
            onClick={(e) => delateSearchInputText(e)}
            type="button"
            className="form-mobile__text-delate-buttom"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="8" cy="8" r="8" fill="#C6C6C6" />
              <path
                d="M10.775 6.275L9.05 8L10.775 9.725C11.075 10.025 11.075 10.475 10.775 10.775C10.625 10.925 10.475 11 10.25 11C10.025 11 9.875 10.925 9.725 10.775L8 9.05L6.275 10.775C6.125 10.925 5.975 11 5.75 11C5.525 11 5.375 10.925 5.225 10.775C4.925 10.475 4.925 10.025 5.225 9.725L6.95 8L5.225 6.275C4.925 5.975 4.925 5.525 5.225 5.225C5.525 4.925 5.975 4.925 6.275 5.225L8 6.95L9.725 5.225C10.025 4.925 10.475 4.925 10.775 5.225C11.075 5.525 11.075 5.975 10.775 6.275Z"
                fill="#EEF1F6"
              />
            </svg>
          </button>
          <button
            className="form-mobile__text-cansel-buttom"
            type="button"
            onClick={() => onCanselClick()}
          >
            Відміна
          </button>
        </form>

        <ul className="lpu-list">
          {currentLpu &&
            currentLpu.slice(0, 20).map((item) => {
              return (
                 <div className="mobile-lpu-screen-item" key={uniqid()} onClick={() => onClickItemHendler(item)}>
                  <div className="mobile-lpu-screen-item-content">
                    <div className="mobile-lpu-screen-item-status-image" style={{background: item.icon}}></div>
                  <div className="mobile-lpu-screen-item-text-block">
                    <p className="mobile-lpu-screen-item-name">{item.name}</p>
                    <p className="mobile-lpu-screen-item-category">{item.specialization}</p>
                    <p className="mobile-lpu-screen-item-adress">{item.adress}</p>
                  </div>
                  </div>
                </div>
              );
            })}
        </ul>
        {currentLpu.length === 0 && (
          <div className="search-mobile-screen__empty-list-image-container">
            <img
              className="search-mobile-screen__empty-list-image"
              src={emptyListImage}
              alt="empty-list"
            />
          </div>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  lpu: state.lpus.lpu,
  lpuCategories: state.lpus.lpuCategories,
  userLocation: state.user.userData.userLocation
});

const mapDispatchToProps = (dispatch) => ({
  setLpuCurrentSearchList(lpuList) {
    dispatch(setCurrentLpuList(lpuList));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileSearchLpuList);
