import React from "react";
import connect from "react-redux/es/connect/connect";
import { setCurrentLpuList } from "../../../../actions/lpu/setCurrentLpuList";
import { setFilteredCategories } from "../../../../actions/lpu/setFilteredCategories";

const SPECIALIZATION_COLORS = {
  "0": '#FF0000',
  "1": '#00FF00',
  "2": '#0000FF',
  "3": '#FFFF00',
  "4": '#c2ffc2',
  "5": '#00FFFF',
  "6": '#FF00FF',
  "7": '#228B22',
  "8": '#FFA500',
  "9": '#181818',
  "10": '#800080',
}

function MobileFilterLpuList({
  onCanselClickHandle,
  lpu,
  setLpuCurrentSearchList,
  lpuCategories,
  onBackToMapClickHandle,
  filteredCategories,
  onCheckClickHandle,
}) {
  const onClickBackToMap = (evt) => {
    evt.preventDefault();
    onBackToMapClickHandle();
  };

  const onCanselClick = (evt) => {
    evt.preventDefault();
    onCanselClickHandle();
  };
console.log({lpuCategories})
console.log({filteredCategories})
  return (
    <div className={`search-mobile-screen`}>
      <form className="form-mobile-filter">
        <h2 className="form-mobile__title">Фільтр по ЛПУ</h2>
        <button
          className="form-mobile__text-cansel-buttom"
          onClick={(evt) => onCanselClick(evt)}
        >
          Відміна
        </button>
      </form>
      <form className="category-form">
        {lpuCategories.map((item) => {
          return (
            <div className="category-form__block" key={item.id}>
              <input
                className="category-form__block-input"
                type="checkbox"
                id={item.id}
                onChange={(evt) => onCheckClickHandle(evt, item)}
                checked={
                  filteredCategories.indexOf(item.id) >= 0 ? true : false
                }
              />
              <label className="category-form__block-label" htmlFor={item.id}>
                <div className="category-form__block-img" style={{background: SPECIALIZATION_COLORS[item.id]}}></div>
                {/* <img
                  className="category-form__block-img"
                  src={item.icon}
                  alt={item.name}
                /> */}
                <span>{item.name}</span>
              </label>
            </div>
          );
        })}
      </form>
      <div className="search-mobile-screen__button-container">
        <div className="search-mobile-screen__button-wrapper">
          <button onClick={(evt) => onClickBackToMap(evt)}>
            Фільтрувати
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  lpu: state.lpus.lpu,
  lpuCategories: state.lpus.lpuCategories,
  filteredCategories: state.lpus.filteredCategories,
});

const mapDispatchToProps = (dispatch) => ({
  setLpuCurrentSearchList(lpuList) {
    dispatch(setCurrentLpuList(lpuList));
  },
  setCheckedCategories(categories) {
    dispatch(setFilteredCategories(categories));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileFilterLpuList);
