import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
//import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers/index";
import { usePromiseTracker } from "react-promise-tracker";
import logo from "../src/assets/img/logo_white_loader.png";
import ApiService from "./service/api/api.service";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

// const store = createStore(
//     reducers,
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );

// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker
//     .register("../firebase-messaging-sw.js")
//     .then(function (registration) {
//       console.log("Registration successful, scope is:", registration.scope);
//     })
//     .catch(function (err) {
//       console.log("Service worker registration failed, error:", err);
//     });
// }

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk.withExtraArgument(ApiService)))
);

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div className="loader-wrapper fade-element">
        <div className="loader">
          <img src={logo} alt="logo" />
        </div>
      </div>
    )
  );
};

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
      <LoadingIndicator />
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();
