export const LPU_TYPE = {
  POLICLINIC: ["polyclinic", "./img/marker-green.svg", "#EA2448"],
  PHARMACY: ["pharmacy", "./img/marker-red.svg", "#519025"],
};

export const FILE_TYPES = {
  PNG: "png",
  JPEG: "jpeg",
  JPG: "jpg",
  PDF: "pdf",
  DOCX: "docx",
  XLSX: "xlsx",
};

export const ITEM_DESCRIPTION = {
  REFUND_TICKET: "Заява на відшкодування",
  MEDICAL_REPORT: "Медичний висновок(заключення), аналізи тощо*",
  PERFOMED_ACT: "Акт виконаних робіт",
  INVOICE: "Чек який підтверджуює оплату*",
  PASPORT: "Паспорт*",
  COD: "ІПН*",
  ISURANCE: "Страховий поліс*",
  OTHER: "Інші документи",
  IBAN: "Введіть свій IBAN*",
};

export const ActionType = {
  GET_LAYOUTS: "GET_LAYOUTS",
  ADD_MESSAGE: "ADD_MESSAGE",
  GET_USER_DATA: "GET_USER_DATA",
  UPDATE_USER_TEL: "UPDATE_USER_TEL",
  SET_LPU: "SET_LPU",
  SET_CURRENT_LPU_LIST: "SET_CURRENT_LPU_LIST",
  SET_LPU_CATEGORIES: "SET_LPU_CATEGORIES",
  SET_FILTERED_CATEGORIES: "SET_FILTERED_CATEORIES",
  SET_REFUND_DATA: "SET_REFUND_DATA",
  SET_REFUND_TICKETS: "SET_REFUND_TICKETS",
  SET_MAPFILTER_OPEN: "SET_MAPFILTER_OPEN",
  SET_VISITS: "SET_VISITS",
  SET_VISIT_FORM_SUBMIT_STATUS: "SET_VISIT_FORM_SUBMIT_STATUS",
  IS_OPEN_CAR: "IS_OPEN_CAR",
  IS_OPEN_PLAIN: "IS_OPEN_PLAIN",
  IS_OPEN_GREEN_CARD: "IS_OPEN_GREEN_CARD",
};

export const TICKETS_PER_PAGE = 5;

export const DOCUMENT_TYPES = {
  fileOrder: "Заява на відшкодовування",
  filesAct: "Акт виконаних робіт",
  filesCopyInn: "Копія коду",
  filesCopyInsurance: "Копія страхового полісу",
  filesCopyPassport: "Копія паспорту",
  filesMedicalReturn: "Медичний висновок",
  filesPayMentReceipt: "Чек,квитанція оплати",
};

export const TICKET_STATUS = {
  pending: "Зареєстрована в системі",
  check: "Перевірка на відповідність документів",
  work: "На розгляді у страхового менеджера",
  final:
    "Пакет документів перевірено для продовження необхідно надіслати паперовий варіант",
  cancelled: "Відмовлено у виплаті",
  success: "Кошти відшкодовано",
};

export const MAX_FILE_SIZE = 10585777;
