import React, { Component } from "react";
import logo from "../../assets/img/logo_color.png";
import { Link, Redirect } from "react-router-dom";
import {
  TokenService,
  ProfileService,
} from "../../service/token/storage.service";
import ApiService from "../../service/api/api.service";
import InputMask from "react-input-mask";
// import { isTel, transformTelNumber } from '../validator/tel.validator';
import { trackPromise } from "react-promise-tracker";
import { createBrowserHistory } from "history";
import "./login.scss";

class Login extends Component {
  state = {
    password: "",
    tel: "",
    card: "",
    isFormError: false,
    telErrorText: "",
    cardErrorText: "",
    passwordErrorText: "",
  };

  handleInput = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  // checkPhoneNumber = () => {
  //     if(isTel(this.state.tel)) {
  //         this.refs.passwordInput.focus();
  //     }
  // };

  clearError = () => {
    this.setState({ isFormError: false });
  };

  clearCardError = () => {
    this.setState({ clearCardError: "" });
  };

  submitLoginForm = () => {
    this.clearCardError();
    if (this.state.password.length !== 0 && this.state.card.length !== 0) {
      // if(isTel(this.state.tel)) {
      let data = {
        query: `query {
                            authLogin(card: "${this.state.card}", password: "${this.state.password}") {
                                success,
                                message,
                                errors,
                                access_token
                              }
                        }`,
      };

      trackPromise(
        ApiService.post("/graphql", data)
          .then((response) => {
            const { data } = response.data;

            if (data.authLogin.success) {
              TokenService.saveToken(data.authLogin.access_token);
              ApiService.setHeader();
              this.props.showGreeting();
              if (this.props.match.params.profile === "add-profile") {
              }
              TokenService.removePreviosToken();
              // this.props.history.push('/dashboard');
              return <Redirect to={`/dashboard`} />;
            } else {
              let errors = JSON.parse(data.authLogin.errors);

              if (Object.keys(errors)[0] === "error_password") {
                this.setState({
                  isFormError: true,
                  cardErrorText: "",
                  passwordErrorText: Object.values(errors)[0],
                });
              } else {
                this.setState({
                  isFormError: true,
                  // telErrorText: Object.values(errors)[0],
                  cardErrorText: Object.values(errors)[0],
                  passwordErrorText: "",
                });
              }
            }
          })
          .catch((err) => console.error(err))
      );
      // } else {
      //     this.setState({
      //         isFormError: true,
      //         cardErrorText: "Невірно вказаний номер страхового поліса",
      //     });
      // }
    } else {
      this.setState({
        isFormError: true,
        cardErrorText: "Поле обов'язкове для заповнення",
        passwordErrorText: "Поле обов'язкове для заповнення",
      });
    }
  };

  handleKeydown = (event) => {
    if (event.keyCode === 13) {
      this.submitLoginForm();
    }
  };

  componentDidMount() {
    //const history = createBrowserHistory();
    // this.backListener = this.props.history.listen((location) => {
    //   if (location.action === "POP") {
    //     // Do your stuff
    //     console.log("BACK");
    //   }
    // });
  }

  componentDidMount() {
    // if (TokenService.getPreviosToken()) {
    //   TokenService.(TokenService.getPreviosToken());
    //   ApiService.setHeader();
    // }
    if (this.props.history.action === "POP") {
      console.log("POP");
    }
  }

  render() {
    const {
      isFormError,
      // telErrorText,
      cardErrorText,
      passwordErrorText,
    } = this.state;

    console.log({ History: this.props });

    return (
      <div className="check_in">
        <div className="check_in__logo">
          <img src={logo} alt="logo" />
        </div>
        <h1 className="caption">Авторизація</h1>
        <p className="desc">Ласкаво просимо до Вашого особистого кабінету</p>
        <form
          className={`check_in__form authorization__form ${
            isFormError ? "error" : ""
          } `}
        >
          <div className="check_in__form__row">
            <label>Номер страхового поліса:</label>
            <InputMask
              name="card"
              type="card"
              required
              onFocus={this.clearError}
              onChange={this.handleInput}
            />
            <div className="help__error">{cardErrorText}</div>
          </div>
          <div className="check_in__form__row">
            <label>Пароль:</label>
            <input
              type="password"
              name="password"
              required
              onFocus={this.clearError}
              onChange={this.handleInput}
              onKeyDown={this.handleKeydown}
              ref="passwordInput"
            />
            <div className="help__error">{passwordErrorText}</div>
          </div>
          <button
            type="button"
            className="button button__success"
            onClick={this.submitLoginForm}
          >
            Увійти
          </button>
          <div className="link__other">
            <div className="link__other__row">
              <span>Забули пароль?</span>
              <Link to={`/recovery`}>Отримати новий пароль</Link>
            </div>
            <div className="link__other__row">
              <span>Ще не зареєстровані?</span>
              <Link to={`/register`}>Зареєструватися</Link>
            </div>
          </div>
          <div className="help__block__error">
            <span>Якщо у Вас виникла проблема при реєстрації,</span>
            <span>будь ласка, зателефонуйте на номер</span>
            <a href="tel:+38(044)290-71-71">+38(044)290-71-71</a>
          </div>
        </form>
      </div>
    );
  }
}

export default Login;
