import React from 'react';

export function BackButton() {
    return (
        <svg width="27" height="17" viewBox="0 0 27 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 8.5L26 8.5" stroke="#519025" strokeWidth="2" stroke-linecap="round"/>
<path d="M1 8.50002L8.40592 1" stroke="#519025" strokeWidth="2" stroke-linecap="round"/>
<path d="M1 8.5L8.40592 16" stroke="#519025" strokeWidth="2" stroke-linecap="round"/>
</svg>
    )
}