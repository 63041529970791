import React from "react";
import PropTypes from 'prop-types';

export function MoneyButtonMobile({isActive, onClick}) {

  const activeColor = 'black';
  const defaultColor = '#6F8195';

  return (
  <svg
    onClick={onClick}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM14.6085 15.4632C13.6032 16.4686 12.3563 17 11.0025 17C8.04535 17 6.1644 14.1329 7.66141 11.8571H5.85717C5.38375 11.8571 5.00002 11.4733 5.00002 10.9999C5.00002 10.5266 5.38375 10.1428 5.85717 10.1428H10.8894L12.0034 9.84329C12.6865 9.65959 13.1454 9.09109 13.1454 8.42852C13.1454 7.48325 12.1841 6.71423 11.0025 6.71423C10.2159 6.71423 9.49238 7.06218 9.11438 7.62232C8.84957 8.01473 8.3168 8.11818 7.92439 7.85332C7.53192 7.58852 7.42858 7.05575 7.69334 6.66334C8.38575 5.63734 9.65374 5 11.0025 5C14.0198 5 15.8044 7.92795 14.3396 10.1429H16.1429C16.6163 10.1429 17 10.5266 17 11C17 11.4734 16.6163 11.8571 16.1429 11.8571H11.1157L10.0016 12.1567C9.57247 12.2721 9.21584 12.5493 9.0232 12.9176C8.08318 14.714 11.1873 16.4601 13.3964 14.251C13.7311 13.9163 14.2738 13.9163 14.6085 14.251C14.9433 14.5858 14.9433 15.1285 14.6085 15.4632Z"
      fill={isActive ? activeColor : defaultColor}
    />
  </svg>);
}

MoneyButtonMobile.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func
};
