import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import TBTLogo from "./svg/TbtLogo";
import Phone from "./svg/phone";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useSelector } from "react-redux";

const InsuranceCard = ({ onClickHandler, isRotate, isDesckTop }) => {
  const { width, height } = useWindowSize();
  const [imageUrl, setImageUrl] = useState(null);
  const [cardHeight, setCardHeight] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);
  const [previosDementions, setPreviosDementions] = useState(null);

  const userData = useSelector((state) => state.user.userData);
  const card = useRef();

  const { username, card_number, polis_vzk } = userData;
  console.log({userData})

  let insurance_company = null;
  let active_contract_from = null;
  let active_contract_until = null;
  let phoneCompany = null;

  if (userData.insurance_information) {
    insurance_company = userData.insurance_information.insurance_company;
    active_contract_from = userData.insurance_information.active_contract_from;
    active_contract_until =
      userData.insurance_information.active_contract_until;
    phoneCompany = userData.insurance_information.phoneCompany;
  }

  const onClickCardHandler = () => {
    if (!isDesckTop && !isRotate) {
      setCardHeight(width - 100);
      setCardWidth((width - 100) * 1.642);
    }

    if (isRotate && previosDementions) {
      setCardHeight(previosDementions.cardHeight);
      setCardWidth(previosDementions.cardWidth);
    }

    onClickHandler();
  };

  useEffect(() => {
    if (userData.insurance_information) {
      setImageUrl(userData.insurance_information.imageUrl);
    }
  }, [userData.insurance_information]);

  useLayoutEffect(() => {
    if (!isRotate) {
      setCardHeight(card.current.offsetWidth * 0.608);
      setCardWidth(card.current.offsetWidth);
      setPreviosDementions({
        cardWidth: card.current.offsetWidth,
        cardHeight: card.current.offsetHeight * 0.608,
      });
    }
  }, [isRotate, userData]);

  return (
    <div
      className={`main-information-mobile-client-card__wrapper ${
        isRotate ? "main-information-mobile-client-card__wrapper--rotate" : ""
      }`}
      style={isRotate ? { width: height } : {}}
    >
      {isDesckTop && (
        <div
          onClick={() => onClickHandler()}
          className="main-information-mobile-client-card__close-button"
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.12973 1.12973C2.63604 -0.376577 5.07825 -0.376577 6.58456 1.12973L18 12.5452L29.4154 1.12973C30.9218 -0.376577 33.364 -0.376577 34.8703 1.12973C36.3766 2.63604 36.3766 5.07825 34.8703 6.58456L23.4548 18L34.8703 29.4154C36.3766 30.9218 36.3766 33.364 34.8703 34.8703C33.364 36.3766 30.9218 36.3766 29.4154 34.8703L18 23.4548L6.58456 34.8703C5.07825 36.3766 2.63604 36.3766 1.12973 34.8703C-0.376577 33.364 -0.376577 30.9218 1.12973 29.4154L12.5452 18L1.12973 6.58456C-0.376577 5.07825 -0.376577 2.63604 1.12973 1.12973Z"
              fill="white"
            />
          </svg>
        </div>
      )}
      <div
        ref={card}
        onClick={() => onClickCardHandler()}
        className="main-information-mobile-client-card"
        style={
          isRotate
            ? {
                width: cardWidth,
                height: cardHeight,
                paddingRight: cardWidth * 0.044,
                paddingLeft: cardWidth * 0.044,
                paddingTop: cardWidth * 0.05,
                paddingBottom: cardWidth * 0.023,
              }
            : {
                width: "100%",
                height: cardHeight,
                paddingRight: cardWidth * 0.044,
                paddingLeft: cardWidth * 0.044,
                paddingTop: cardWidth * 0.05,
                paddingBottom: cardWidth * 0.023,
              }
        }
      >
        <div>
          <div className="main-information-mobile-client-card__header">
            <div>
              <span
                style={{
                  fontSize: cardWidth * 0.047,
                  marginBottom: cardWidth * 0.02,
                }}
                className="main-information-mobile-client-card__name"
              >
                {username}
              </span>
              <div className="main-information-mobile-client-card__police-container">
                <span
                  className="main-information-mobile-client-card__polis"
                  style={{ fontSize: cardWidth * 0.029 }}
                >
                  Поліс №:{" "}
                </span>
                <span
                  style={{
                    fontSize: cardWidth * 0.047,
                  }}
                  className="main-information-mobile-client-card__name"
                >
                  {card_number}
                </span>
              </div>
              {polis_vzk && (   <div className="main-information-mobile-client-card__police-container">
                <span
                  className="main-information-mobile-client-card__polis"
                  style={{ fontSize: cardWidth * 0.029 }}
                >
                  Поліс ВЗК:{" "}
                </span>
                <span
                  style={{
                    fontSize: cardWidth * 0.035,
                  }}
                  className="main-information-mobile-client-card__name"
                >
                  {polis_vzk}
                </span>
              </div>
              )}
            </div>

            <div className="main-information-mobile-client-card__card-name-wrapper">
              <img
                src={imageUrl}
                width={cardWidth * 0.1661}
                alt="insurance-name"
              ></img>
            </div>
          </div>
        </div>
        <div style={{ fontSize: cardWidth * 0.02985074 }}>
          <div style={{ marginBottom: cardWidth * 0.002 }}>
            <span className="main-information-mobile-client-card__company-name">
              {insurance_company}
            </span>
          </div>
          <div
            style={{ marginBottom: cardWidth * 0.01 }}
            className="main-information-mobile-client-card__active-period"
          >
            <span>Дійсне: </span>
            <span>
              з {active_contract_from} по {active_contract_until}
            </span>
          </div>
          <div className="main-information-mobile-client-card__phone-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={cardWidth * 0.029}
              height={cardWidth * 0.029}
              fill="none"
              viewBox="0 0 10 9"
            >
              <path
                fill="#9ed99d"
                fillRule="evenodd"
                d="M9.21 6.389c-.245-.119-1.417-.694-1.636-.773-.22-.078-.382-.118-.54.119-.158.237-.623.772-.759.93-.136.158-.28.176-.522.057a6.459 6.459 0 01-1.926-1.18A6.972 6.972 0 012.51 3.9c-.136-.237 0-.364.106-.483.105-.118.236-.28.36-.439.034-.04.06-.079.087-.114.058-.09.108-.186.15-.285a.412.412 0 000-.412c-.058-.119-.54-1.29-.738-1.756-.197-.465-.4-.39-.54-.39S1.637 0 1.475 0a.878.878 0 00-.637.294A2.677 2.677 0 000 2.282c.004.273.045.545.123.807.194.596.49 1.153.878 1.646.118.157 1.654 2.632 4.085 3.589 2.43.956 2.44.632 2.878.588.44-.044 1.418-.57 1.61-1.128.157-.354.208-.746.146-1.128-.115-.096-.268-.149-.51-.267z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div className="main-information-mobile-client-card__phone-number">
              {phoneCompany}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceCard;
