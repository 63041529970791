import { setRefundTickets } from "./refund/setRefundTickets";
import { TokenService, UserDataService } from "../service/token/storage.service";
import { refundTicketsListAdapter } from "../utils/utils";
import { setVisits, setVisitSubmitStatus } from "./visits/setVisits";
import { visitListAdapter } from "../utils/utils";
import { trackPromise } from "react-promise-tracker";
import { formatDateForm } from "../utils/utils";
import { addAllVisits } from "./user/addAllVisits";


export const createMedicalVisits = (formData) => (dispatch, _getState, api) => {
  const {
    date,
    medical_complaints,
    time,
    medical_need_sick_leave,
    is_offline,
    city,
    city_area,
    medical_specialist,
    comment
  } = formData;


  let data = {
    query: `mutation {
      orderMedicalVisit(access_token: "${TokenService.getToken()}", visit_id: null) {
        create(
              date: "${date}",
              time: "${time}",
              medical_complaints: "${medical_complaints}",
              medical_need_sick_leave: "${medical_need_sick_leave}",
              is_offline: "${is_offline}",
              medical_specialist: "${medical_specialist}",
              city: "${city}",
              city_area: "${city_area}",
              comment: "${comment}"            
            )
      }
    }`,
  };

  api
  .post("/cabinet", data)
  .then((response) => {
    const { data } = response.data;
    console.log('new')
    console.log({data})
 
  })
  .catch((err) => {
    console.error(err);
  });
}

export const updateMedicalVisits = (formData) => (dispatch, _getState, api) => {
  const {
    id,
    date,
    time,
    medical_need_sick_leave,
    is_offline,
    city,
    city_area,
    medical_specialist,
    comment
  } = formData;

  let data = {
    query: `mutation {
      orderMedicalVisit(access_token: "${TokenService.getToken()}", , visit_id: ${id}) {
            update(
              date: "${date}",
              time: "${time}",
              medical_need_sick_leave: "${medical_need_sick_leave}",
              is_offline: "${is_offline}",
              medical_specialist: "${medical_specialist}",
              city: "${city}",
              city_area: "${city_area}",
              comment: "${comment}"            
            )
      }
    }`,
  };

  api
  .post("/cabinet", data)
  .then((response) => {
    const { data } = response.data;
    console.log('update')
    console.log({data})
 
  })
  .catch((err) => {
    console.error(err);
  });
}

export const getMedicalVisits = () => (dispatch, _getState, api) => {
  let data = {
    query: `query {                 
                      
                    orderMedicalVisits(access_token: "${TokenService.getToken()}") {
                        id,
                        date,
                        time,
                        medical_complaints,
                        medical_need_sick_leave,
                        is_offline,
                        medical_specialist,
                        city,
                        city_area,
                        comment, 
                      },                                                                        
                     
                }`,
  };

  api
  .post("/cabinet", data)
  .then((response) => {
    const { data } = response.data;
    console.log("get")
    console.log(data.orderMedicalVisits)
    try {
      console.log({addAllVisits})
      dispatch(addAllVisits(data.orderMedicalVisits))
    } catch (error) {
      console.log(error.message)
    }
   
  })
  .catch((err) => {
    console.error(err);
  });
}

export const getRefundTickets = () => (dispatch, _getState, api) => {
  let data = {
    query: `query {                 
                      
                      refundRequests(access_token: "${TokenService.getToken()}") {
                        id,
                        status,
                        iban,
                        created_at,
                        refund_amount,
                        refund_date,
                        file_order,
                        files_medical_return,
                        files_act,
                        files_payment_receipt,
                        files_copy_passport,
                        files_copy_inn,
                        files_copy_insurance,  
                        files_other
                      },                                                                        
                     
                }`,
  };

  api
    .post("/cabinet", data)
    .then((response) => {
      const { data } = response.data;

      if (data.refundRequests) {
        const adaptedRefundList = data.refundRequests.map((item) => {
          return refundTicketsListAdapter(item);
        });

        dispatch(setRefundTickets(adaptedRefundList));
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

export const sendVisitData = (formData) => (dispatch, _getState, api) => {
  const {
    dateAplication,
    contractNumber,
    program,
    institutionName,
    medicalCareGeneral,
    medicalCareDetailed,
    price,
  } = formData;

  let data = {
    query: `mutation {
      historyVisit(access_token: "${TokenService.getToken()}", visit_id: null) {
            create(
                date: "${formatDateForm(dateAplication)}",
                contract_number: "${contractNumber}",
                program: "${program}",
                medical_facility: "${institutionName}",
                medical_care_general: "${medicalCareGeneral}",
                medical_care_detailed: "${medicalCareDetailed}",
                price: "${price}"               
            )
      }
    }`,
  };

  trackPromise(
    api
      .post("/cabinet", data)
      .then(() => {
        dispatch(getVisitsList());
        dispatch(setVisitSubmitStatus(true));
      })
      .catch((err) => {
        console.error(err);
        dispatch(setVisitSubmitStatus(false));
      })
  );
};

export const getVisitsList = () => (dispatch, _getState, api) => {
  let data = {
    query: `query {                 
                      
      historyVisits(access_token: "${TokenService.getToken()}") {
        id,
        date,
        contract_number,
        program,
        medical_facility,
        medical_care_general,
        medical_care_detailed,
        city,
        price,
        created_at
      }                                                                    
                     
                }`,
  };

  api
    .post("/cabinet", data)
    .then((response) => {
      const { data } = response.data;

      if (data.historyVisits) {
        const adaptedVisitList = data.historyVisits.map((visit) => {
          return visitListAdapter(visit);
        });
        dispatch(setVisits(adaptedVisitList));
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

export const enableNotifications = () => (dispatch, _getState, api) => {
  if (
    TokenService.getFireBaseToken() !== null &&
    TokenService.getFireBaseToken() !== "null" &&
    TokenService.getFireBaseToken().length > 20
  ) {
    let data;

    data = {
      query: `mutation {
          webPushUser(access_token: "${TokenService.getToken()}", token: null) {
                create (token: "${TokenService.getFireBaseToken()}")
          }
        }`,
    };

    api
      .post("/cabinet", data)
      .then((response) => {})
      .catch((err) => {
        console.error(err);
      });
  } else {
    console.log("Incorrect token");
  }
};

export const disableNotifications = () => (dispatch, _getState, api) => {
  let data;

  data = {
    query: `mutation {
      webPushUser(access_token: "${TokenService.getToken()}", token: "${TokenService.getFireBaseToken()}") {
            delete                 
      }
    }`,
  };

  api
    .post("/cabinet", data)
    .then((response) => {})
    .catch((err) => {
      console.error(err);
    });
};
