
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { TokenService } from '../../service/token/storage.service';

const PrivateRoute = ({component: Component, showToast, greeting, ...rest}) => {
    return (
        <Route {...rest} render={props => (
            TokenService.isLogin ?
                <Component showToast={showToast} greeting={greeting} {...props} />
            : <Redirect to="/dashboard" />
        )} />
    );
};

export default PrivateRoute;