import React from 'react';
import logo from '../../../assets/img/logo__white.png'

export const Greeting = ({greeting}) => {
    const active = greeting ? 'active' : '';

    return (
        <div className={`greeting-wrapper ${active}`}>
            <img src={logo} alt="logo"/>
            <div className="greeting">
                <div className="greeting--mask">
                    <div className="greeting--mask-inner">
                        Ласкаво&nbsp;просимо&nbsp;:)
                    </div>
                </div>
            </div>
        </div>
    )
}