import React, { useState } from "react";
import { FileInputBlock } from "./fileInputBlock";
import { IbanInputBlock } from "./ibanInputBlock";
import { SuccessScreen } from "./successScreen";
import { ErrorSubmitScreen } from "./errorSubmitScreen";
import "./refundForm.scss";
import RefundList from "../../../dashboard/main/lists/refundList";
import ApiService from "../../../../service/api/api.service";
import { TokenService } from "../../../../service/token/storage.service";
import { trackPromise } from "react-promise-tracker";
import { FileValidationErrorScreen } from "./fileValidationErrorScreen";
import { connect } from "react-redux";
import { getRefundTickets } from "../../../../actions/api-actions";
import { ITEM_DESCRIPTION } from "../../../../const/const";

const ERROR_MESSAGE =
  "Переконайтеся що додали усі необхідні файли та спробуйте знову";

const ERROR_MESSAGE_IBAN =
  "Невірний формат IBAN. Будь-ласка перевірте і спробуйте знову";

const FIELD_TYPE = {
  REFUND_TICKET: "refundTicket",
  MEDICAL_REPORT: "medicalReport",
  PERFOMED_ACT: "perfomedAct",
  INVOICE: "invoice",
  PASPORT: "pasport",
  COD: "cod",
  ISURANCE: "isuransce",
  IBAN: "iban",
  OTHER: "other"
};

const FILE_NUMBER_MIN = 1;
const FILE_NUMBER_MAX = 3;
const START_BLOCK_NUMBER = 1;
const OTHER_FILE_NUMBER = 10

let filesToSend = {
  refundTicket: [],
  medicalReport: [],
  perfomedAct: [],
  invoice: [],
  pasport: [],
  cod: [],
  isuransce: [],
  other: []
};

let addedFileNumber = 0;

let isOnlyIbanError = false;

export function RefundForm({
  isMobile = false,
  onRefundFormCloseHandle,
  upadateRefundTickets,
}) {
  const [formFields, setFormFields] = useState({
    refundTicket: [],
    medicalReport: [],
    perfomedAct: [],
    invoice: [],
    pasport: [],
    cod: [],
    insurance: [],
    other: [],
    iban: "",
  });

  const [mobileFormBlockNumber, setMobileFormNumber] =
    useState(START_BLOCK_NUMBER);
  const [isValiationError, setIsValidationError] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(true);

  const sendData = (data, id) => {
    trackPromise(
      ApiService.post("/cabinet", data)
        .then((responce) => {
          addedFileNumber = addedFileNumber - 1;
          if (addedFileNumber === 0) {
            sendConfirm(id);
          }
          setIsSubmitSuccess(true);
        })
        .catch((err) => {
          setMobileFormNumber(mobileFormBlockNumber + 1);
          setIsSubmitSuccess(false);
          console.log(err);
        })
    );
  };

  const sendConfirm = (id) => {
    const data = {
      query: `mutation {
          refundRequest(access_token: "${TokenService.getToken()}", refund_request_id: ${id}) {
                confirm
          }
        }`,
    };

    trackPromise(
      ApiService.post("/cabinet", data)
        .then((responce) => {
          resetForm();
          addedFileNumber = 0;
          setMobileFormNumber(mobileFormBlockNumber + 1);
          upadateRefundTickets();
          setIsSubmitSuccess(true);
        })
        .catch((err) => {
          console.error(err);
          setMobileFormNumber(mobileFormBlockNumber + 1);
          setIsSubmitSuccess(false);
        })
    );
  };

  const sendFiles = (id) => {
    filesToSend.refundTicket.forEach(async (item) => {
      const data = {
        query: `mutation {
          refundRequest(access_token: "${TokenService.getToken()}", refund_request_id: ${id}) {
            update(file_order: "${item}")   
            
          }
        }`,
      };

      sendData(data, id);
    });

    filesToSend.medicalReport.forEach(async (item) => {
      const data = {
        query: `mutation {
          refundRequest(access_token: "${TokenService.getToken()}", refund_request_id: ${id}) {
                update(files_medical_return: "${item}")
          }
        }`,
      };

      sendData(data, id);
    });

    filesToSend.perfomedAct.forEach(async (item) => {
      const data = {
        query: `mutation {
          refundRequest(access_token: "${TokenService.getToken()}", refund_request_id: ${id}) {
                update(files_act: "${item}")
          }
        }`,
      };

      sendData(data, id);
    });

    filesToSend.invoice.forEach(async (item) => {
      const data = {
        query: `mutation {
          refundRequest(access_token: "${TokenService.getToken()}", refund_request_id: ${id}) {
                update(files_payment_receipt: "${item}")
          }
        }`,
      };

      sendData(data, id);
    });

    filesToSend.pasport.forEach(async (item) => {
      const data = {
        query: `mutation {
          refundRequest(access_token: "${TokenService.getToken()}", refund_request_id: ${id}) {
                update(files_copy_passport: "${item}")
          }
        }`,
      };

      sendData(data, id);
    });

    filesToSend.cod.forEach(async (item) => {
      const data = {
        query: `mutation {
          refundRequest(access_token: "${TokenService.getToken()}", refund_request_id: ${id}) {
                update(files_copy_inn: "${item}")
          }
        }`,
      };

      sendData(data, id);
    });

    filesToSend.isuransce.forEach(async (item) => {
      const data = {
        query: `mutation {
          refundRequest(access_token: "${TokenService.getToken()}", refund_request_id: ${id}) {
                update(files_copy_insurance: "${item}")
          }
        }`,
      };

      sendData(data, id);
    });

    filesToSend.other.forEach(async (item) => {
      const data = {
        query: `mutation {
          refundRequest(access_token: "${TokenService.getToken()}", refund_request_id: ${id}) {
                update(files_other: "${item}")
          }
        }`,
      };

      sendData(data, id);
    });
  };

  const sendIban = () => {
    let data;
    let id;

    const filesKeys = Object.keys(formFields).filter((key) => key !== "iban");

    filesKeys.forEach((key) => {
      addedFileNumber = addedFileNumber + formFields[key].length;
    });

    data = {
      query: `mutation {
        refundRequest(access_token: "${TokenService.getToken()}", refund_request_id: null) {
              create(
                  iban: "${formFields.iban}",                                  
              ) {
                success,
                message,
                id
            }             
        }
      }`,
    };

    trackPromise(
      ApiService.post("/cabinet", data)
        .then((responce) => {
          id = responce.data.data.refundRequest.create.id;
          sendFiles(id);

          setIsSubmitSuccess(true);
        })
        .catch((err) => {
          console.error(err);
          setMobileFormNumber(mobileFormBlockNumber + 1);

          setIsSubmitSuccess(false);
        })
    );
  };

  const resetForm = () => {
    setFormFields({
      refundTicket: [],
      medicalReport: [],
      perfomedAct: [],
      invoice: [],
      pasport: [],
      cod: [],
      insurance: [],
      other: [],
      iban: "",
    });

    filesToSend.refundTicket = [];
    filesToSend.medicalReport = [];
    filesToSend.perfomedAct = [];
    filesToSend.invoice = [];
    filesToSend.pasport = [];
    filesToSend.cod = [];
    filesToSend.isuransce = [];
    filesToSend.other = [];
  };

  const validationCheckError = () => {
    return (
      formFields.medicalReport.length === 0 ||
      formFields.invoice.length === 0 ||
      formFields.pasport.length === 0 ||
      formFields.cod.length === 0 ||
      formFields.insurance.length === 0 ||
      formFields.iban === null
    );
  };

  const onSubmitHandler = (evt) => {
    evt.preventDefault();

    // check is only iban validation error

    if (!validationCheckError() && formFields.iban.length < 15) {
      setIsValidationError(true);
      isOnlyIbanError = true;
      return;
    }

    if (validationCheckError()) {
      setIsValidationError(true);
      isOnlyIbanError = false;
      return;
    }

    addedFileNumber =
      formFields.refundTicket.length +
      formFields.medicalReport.length +
      formFields.perfomedAct.length +
      formFields.invoice.length +
      formFields.pasport.length +
      formFields.cod.length +
      formFields.other.length +
      formFields.insurance.length;

    formFields.refundTicket.forEach((element) => {
      readFile(element, FIELD_TYPE.REFUND_TICKET);
    });

    formFields.medicalReport.forEach((element) => {
      readFile(element, FIELD_TYPE.MEDICAL_REPORT);
    });

    formFields.perfomedAct.forEach((element) => {
      readFile(element, FIELD_TYPE.PERFOMED_ACT);
    });

    formFields.invoice.forEach((element) => {
      readFile(element, FIELD_TYPE.INVOICE);
    });

    formFields.pasport.forEach((element) => {
      readFile(element, FIELD_TYPE.PASPORT);
    });

    formFields.cod.forEach((element) => {
      readFile(element, FIELD_TYPE.COD);
    });

    formFields.insurance.forEach((element) => {
      readFile(element, FIELD_TYPE.ISURANCE);
    });

    formFields.other.forEach((element) => {
      readFile(element, FIELD_TYPE.OTHER);
    });
  };

  const readFile = (file, type) => {
    let reader = new FileReader();

    reader.onloadend = () => {
      switch (type) {
        case FIELD_TYPE.REFUND_TICKET:
          filesToSend.refundTicket = [
            ...filesToSend.refundTicket,
            reader.result,
          ];
          break;
        case FIELD_TYPE.MEDICAL_REPORT:
          filesToSend.medicalReport = [
            ...filesToSend.medicalReport,
            reader.result,
          ];
          break;
        case FIELD_TYPE.PERFOMED_ACT:
          filesToSend.perfomedAct = [...filesToSend.perfomedAct, reader.result];
          break;
        case FIELD_TYPE.INVOICE:
          filesToSend.invoice = [...filesToSend.invoice, reader.result];
          break;
        case FIELD_TYPE.PASPORT:
          filesToSend.pasport = [...filesToSend.pasport, reader.result];
          break;
        case FIELD_TYPE.COD:
          filesToSend.cod = [...filesToSend.cod, reader.result];
          break;
        case FIELD_TYPE.ISURANCE:
          filesToSend.isuransce = [...filesToSend.isuransce, reader.result];
          break;
        case FIELD_TYPE.OTHER:
          filesToSend.other = [...filesToSend.other, reader.result]
          break;
        default:
          break;
      }

      addedFileNumber = addedFileNumber - 1;

      if (addedFileNumber === 0) {
        sendIban();
      }
    };

    reader.readAsDataURL(file);
  };

  const onCloseErrorSubmitScreen = () => {
    setMobileFormNumber(1);
  };

  const onInputFileHandler = (type, files) => {
    switch (type) {
      case FIELD_TYPE.REFUND_TICKET:
        setFormFields(
          Object.assign({}, formFields, {
            refundTicket: files.slice(),
          })
        );
        break;
      case FIELD_TYPE.MEDICAL_REPORT:
        setFormFields(
          Object.assign({}, formFields, {
            medicalReport: files.slice(),
          })
        );
        break;
      case FIELD_TYPE.PERFOMED_ACT:
        setFormFields(
          Object.assign({}, formFields, {
            perfomedAct: files.slice(),
          })
        );
        break;
      case FIELD_TYPE.INVOICE:
        setFormFields(
          Object.assign({}, formFields, {
            invoice: files.slice(),
          })
        );
        break;
      case FIELD_TYPE.PASPORT:
        setFormFields(
          Object.assign({}, formFields, {
            pasport: files.slice(),
          })
        );
        break;
      case FIELD_TYPE.COD:
        setFormFields(
          Object.assign({}, formFields, {
            cod: files.slice(),
          })
        );
        break;
      case FIELD_TYPE.ISURANCE:
        setFormFields(
          Object.assign({}, formFields, {
            insurance: files.slice(),
          })
        );
        break;
      case FIELD_TYPE.OTHER:
        setFormFields(
          Object.assign({}, formFields, {
            other: files.slice(),
          })
        );
        break
      default:
        break;
    }
  };

  const onChangeIbanHandler = (value) => {
    setFormFields(
      Object.assign({}, formFields, {
        iban: value,
      })
    );
  };

  const onClickNextBlockHandler = (evt) => {
    evt.preventDefault();
    setMobileFormNumber(mobileFormBlockNumber + 1);
  };

  const onClickBackButtonHandler = (evt) => {
    evt.preventDefault();
    setMobileFormNumber(mobileFormBlockNumber - 1);
    if (mobileFormBlockNumber - 1 < 1) {
      onRefundFormCloseHandle();
    }
  };

  const onCloseErrorScreenHandler = () => {
    setIsValidationError(false);
  };

  return (
    <>
      {isValiationError && (
        <FileValidationErrorScreen
          onCloseHandler={() => onCloseErrorScreenHandler()}
          errorMessage={`${
            isOnlyIbanError ? ERROR_MESSAGE_IBAN : ERROR_MESSAGE
          }`}
        />
      )}
      {!isMobile && (
        <form className="refund-form">
          <h2 className="refund-form__title">
            Створення заявки на відшкодування витрачених коштів
          </h2>
          <div className="field-block__wraper">
            <FileInputBlock
              itemDescription={ITEM_DESCRIPTION.REFUND_TICKET}
              fileNumber={FILE_NUMBER_MIN}
              filesArray={formFields.refundTicket}
              onInputFileHandler={(files) =>
                onInputFileHandler(FIELD_TYPE.REFUND_TICKET, files)
              }
            />
            <FileInputBlock
              itemDescription={ITEM_DESCRIPTION.MEDICAL_REPORT}
              fileNumber={FILE_NUMBER_MAX}
              filesArray={formFields.medicalReport}
              onInputFileHandler={(files) =>
                onInputFileHandler(FIELD_TYPE.MEDICAL_REPORT, files)
              }
            />
            <FileInputBlock
              itemDescription={ITEM_DESCRIPTION.PERFOMED_ACT}
              fileNumber={FILE_NUMBER_MAX}
              filesArray={formFields.perfomedAct}
              onInputFileHandler={(files) =>
                onInputFileHandler(FIELD_TYPE.PERFOMED_ACT, files)
              }
            />
            <FileInputBlock
              itemDescription={ITEM_DESCRIPTION.INVOICE}
              fileNumber={FILE_NUMBER_MAX}
              filesArray={formFields.invoice}
              onInputFileHandler={(files) =>
                onInputFileHandler(FIELD_TYPE.INVOICE, files)
              }
            />
            <FileInputBlock
              itemDescription={ITEM_DESCRIPTION.PASPORT}
              fileNumber={FILE_NUMBER_MAX}
              filesArray={formFields.pasport}
              onInputFileHandler={(files) =>
                onInputFileHandler(FIELD_TYPE.PASPORT, files)
              }
            />
            <FileInputBlock
              itemDescription={ITEM_DESCRIPTION.COD}
              fileNumber={FILE_NUMBER_MAX}
              filesArray={formFields.cod}
              onInputFileHandler={(files) =>
                onInputFileHandler(FIELD_TYPE.COD, files)
              }
            />
            <FileInputBlock
              itemDescription={ITEM_DESCRIPTION.ISURANCE}
              fileNumber={FILE_NUMBER_MAX}
              filesArray={formFields.insurance}
              onInputFileHandler={(files) =>
                onInputFileHandler(FIELD_TYPE.ISURANCE, files)
              }
            />
             <FileInputBlock
              itemDescription={ITEM_DESCRIPTION.OTHER}
              fileNumber={OTHER_FILE_NUMBER}
              filesArray={formFields.other}
              onInputFileHandler={(files) =>
                onInputFileHandler(FIELD_TYPE.OTHER, files)
              }
              otherFiles={true}
            />
            <IbanInputBlock
              isMobile={isMobile}
              onChange={(value) => onChangeIbanHandler(value)}
              onSubmit={(evt) => onSubmitHandler(evt)}
              itemDescription={ITEM_DESCRIPTION.IBAN}
              iban={formFields.iban}
            />
          </div>
          <div className="refund-form__atantion">
            <div className="refund-form__atantion-image">
              <svg
                width="35"
                height="31"
                viewBox="0 0 35 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.26656 30.1331C3.03136 30.592 3.79616 30.745 4.56096 30.745H30.5642C31.7878 30.745 32.8586 30.2861 33.6234 29.3683C34.5411 28.6035 35 27.3798 35 26.1562C35 25.3914 34.847 24.6266 34.3882 23.8618L21.5395 2.2944C20.7747 1.22368 19.857 0.45888 18.6333 0.15296C17.4096 -0.15296 16.1859 0 15.1152 0.61184C14.5034 0.91776 13.8915 1.5296 13.5856 2.14144L0.583999 23.8618C-0.639681 26.0032 0.125119 28.9094 2.26656 30.1331ZM3.3373 25.3914L16.1859 3.82399C16.3389 3.51807 16.4919 3.36511 16.7978 3.21215C17.5626 2.90623 18.4803 3.05919 18.9392 3.82399L31.7879 25.3914C31.9408 25.6973 31.9408 25.8502 31.9408 26.1562C31.9408 26.615 31.7879 26.921 31.4819 27.2269C31.176 27.5328 30.8701 27.6858 30.4112 27.6858H4.56098C4.40802 27.6858 4.1021 27.6858 3.94914 27.5328C3.18434 27.0739 2.87842 26.1562 3.3373 25.3914ZM19.0922 16.9785V10.8601C19.0922 9.94233 18.4804 9.33049 17.5626 9.33049C16.6449 9.33049 16.033 9.94233 16.033 10.8601V16.9785C16.033 17.8963 16.6449 18.5081 17.5626 18.5081C18.4804 18.5081 19.0922 17.8963 19.0922 16.9785ZM19.0923 23.0969C19.0923 23.5558 18.9393 23.8617 18.6334 24.1676C18.3275 24.4735 18.0216 24.6265 17.4097 24.6265C16.9508 24.6265 16.6449 24.4735 16.339 24.1676C16.0331 23.8617 15.8801 23.5558 15.8801 23.0969C15.8801 22.9177 15.9326 22.791 15.9761 22.686C16.0068 22.6118 16.0331 22.5484 16.0331 22.4851C16.0331 22.3321 16.186 22.1791 16.339 22.0262C16.492 21.8732 16.6449 21.7203 16.7979 21.7203C17.1038 21.5673 17.4097 21.5673 17.7156 21.5673C17.8036 21.6553 17.841 21.6926 17.8859 21.7085C17.9192 21.7203 17.9566 21.7203 18.0216 21.7203C18.098 21.7203 18.1363 21.7585 18.1745 21.7967C18.2128 21.835 18.251 21.8732 18.3275 21.8732C18.4804 21.8732 18.6334 22.0262 18.6334 22.0262C18.7864 22.1791 18.9393 22.3321 18.9393 22.4851C19.0923 22.638 19.0923 22.9439 19.0923 23.0969Z"
                  fill="#787878"
                />
              </svg>
            </div>
            <div>
              Увага! Документи на відшкодування подаються для попереднього
              розгляду, це не виключає необхідності надання документів у
              паперовому вигляді
            </div>
          </div>
          <div className="refund-form__submit-button-wraper">
            <button
              onClick={(evt) => onSubmitHandler(evt)}
              className="refund-form__submit-button"
            >
              Подати заявку на відшкодування витрачених коштів
            </button>
          </div>
          <RefundList inRefundForm={true} />
        </form>
      )}
      {isMobile && mobileFormBlockNumber !== 10 && (
        <form className="refund-form--mobile">
          <div className="title-wrapper__mobile">
            <button
              onClick={(evt) => onClickBackButtonHandler(evt)}
              className="back-button-mobile"
            >
              <svg
                width="27"
                height="17"
                viewBox="0 0 27 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 8.5L26 8.5"
                  stroke="#519025"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M1 8.50002L8.40592 1"
                  stroke="#519025"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M1 8.5L8.40592 16"
                  stroke="#519025"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <h2 className="refund-form__title--mobile">Подача заявки</h2>
            <div className="refund-form__process-indicator-container">
              <div
                style={{ width: 12.5 * mobileFormBlockNumber + "%" }}
                className="refund-form__process-indicator"
              ></div>
            </div>
          </div>

          <div className="field-block__wraper">
            {mobileFormBlockNumber === 1 && (
              <FileInputBlock
                itemDescription={ITEM_DESCRIPTION.REFUND_TICKET}
                fileNumber={FILE_NUMBER_MIN}
                filesArray={formFields.refundTicket}
                onInputFileHandler={(files) =>
                  onInputFileHandler(FIELD_TYPE.REFUND_TICKET, files)
                }
                onClickNextBlockHandler={(evt) => onClickNextBlockHandler(evt)}
                isMobile={isMobile}
              />
            )}
            {mobileFormBlockNumber === 2 && (
              <FileInputBlock
                itemDescription={ITEM_DESCRIPTION.MEDICAL_REPORT}
                fileNumber={FILE_NUMBER_MAX}
                filesArray={formFields.medicalReport}
                onInputFileHandler={(files) =>
                  onInputFileHandler(FIELD_TYPE.MEDICAL_REPORT, files)
                }
                onClickNextBlockHandler={(evt) => onClickNextBlockHandler(evt)}
                isMobile={isMobile}
              />
            )}
            {mobileFormBlockNumber === 3 && (
              <FileInputBlock
                itemDescription={ITEM_DESCRIPTION.PERFOMED_ACT}
                fileNumber={FILE_NUMBER_MAX}
                filesArray={formFields.perfomedAct}
                onInputFileHandler={(files) =>
                  onInputFileHandler(FIELD_TYPE.PERFOMED_ACT, files)
                }
                onClickNextBlockHandler={(evt) => onClickNextBlockHandler(evt)}
                isMobile={isMobile}
              />
            )}
            {mobileFormBlockNumber === 4 && (
              <FileInputBlock
                itemDescription={ITEM_DESCRIPTION.INVOICE}
                fileNumber={FILE_NUMBER_MAX}
                filesArray={formFields.invoice}
                onInputFileHandler={(files) =>
                  onInputFileHandler(FIELD_TYPE.INVOICE, files)
                }
                onClickNextBlockHandler={(evt) => onClickNextBlockHandler(evt)}
                isMobile={isMobile}
              />
            )}
            {mobileFormBlockNumber === 5 && (
              <FileInputBlock
                itemDescription={ITEM_DESCRIPTION.PASPORT}
                fileNumber={FILE_NUMBER_MAX}
                filesArray={formFields.pasport}
                onInputFileHandler={(files) =>
                  onInputFileHandler(FIELD_TYPE.PASPORT, files)
                }
                onClickNextBlockHandler={(evt) => onClickNextBlockHandler(evt)}
                isMobile={isMobile}
              />
            )}
            {mobileFormBlockNumber === 6 && (
              <FileInputBlock
                itemDescription={ITEM_DESCRIPTION.COD}
                fileNumber={FILE_NUMBER_MAX}
                filesArray={formFields.cod}
                onInputFileHandler={(files) =>
                  onInputFileHandler(FIELD_TYPE.COD, files)
                }
                onClickNextBlockHandler={(evt) => onClickNextBlockHandler(evt)}
                isMobile={isMobile}
              />
            )}
            {mobileFormBlockNumber === 7 && (
              <FileInputBlock
                itemDescription={ITEM_DESCRIPTION.ISURANCE}
                fileNumber={FILE_NUMBER_MAX}
                filesArray={formFields.insurance}
                onInputFileHandler={(files) =>
                  onInputFileHandler(FIELD_TYPE.ISURANCE, files)
                }
                onClickNextBlockHandler={(evt) => onClickNextBlockHandler(evt)}
                isMobile={isMobile}
              />
            )}
              {mobileFormBlockNumber === 8 && (
              <FileInputBlock
                itemDescription={ITEM_DESCRIPTION.OTHER}
                fileNumber={OTHER_FILE_NUMBER}
                filesArray={formFields.other}
                onInputFileHandler={(files) =>
                  onInputFileHandler(FIELD_TYPE.OTHER, files)
                }
                onClickNextBlockHandler={(evt) => onClickNextBlockHandler(evt)}
                isMobile={isMobile}
                otherFiles={true}
              />
            )}
            {mobileFormBlockNumber === 9 && (
              <IbanInputBlock
                isMobile={isMobile}
                onChange={(value) => onChangeIbanHandler(value)}
                onSubmit={(evt) => onSubmitHandler(evt)}
                itemDescription={ITEM_DESCRIPTION.IBAN}
                iban={formFields.iban}
              />
            )}
          </div>
        </form>
      )}

      {isMobile && mobileFormBlockNumber === 10 && isSubmitSuccess && (
        <SuccessScreen onClose={(value) => onRefundFormCloseHandle(value)} />
      )}
      {isMobile && mobileFormBlockNumber === 10 && !isSubmitSuccess && (
        <ErrorSubmitScreen onClose={() => onCloseErrorSubmitScreen()} />
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  upadateRefundTickets() {
    dispatch(getRefundTickets());
  },
});

export default connect(null, mapDispatchToProps)(RefundForm);
