import { ActionType } from "../../const/const";

const initialState = {
  visitsList: null,
  isSendVisitSuccess: true,
};

export const visits = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_VISITS:
      return {
        ...state,
        visitsList: action.payload,
      };
    case ActionType.SET_VISIT_FORM_SUBMIT_STATUS:
      return {
        ...state,
        isSendVisitSuccess: action.payload,
      };
    default:
      return state;
  }
};
