import React from "react";

export function MarkerInfo({ cluster, isMobile, closeMarkerInfo, openAppoitDoctorPage }) {
  const [longitude, latitude] = cluster.geometry.coordinates;
  const { name, adress, coverage, specialization, category_id } = cluster.properties;
  console.log({category_id})
  return (
    <>
      {!isMobile && (
        <div className={`point-marker__info-block`}>
          <div className="point-marker__info-block-name-wrapper">
            <div className="point-marker__info-block-icon" style={{background: cluster.properties.iconUrl}}></div>
            <p className="point-marker__info-block-name">{name}</p>
            <div className="point-marker__info-block-close-button-wrapper">
              <div
                onClick={() => closeMarkerInfo()}
                className="point-marker__info-block-close-button"
              ></div>
            </div>
          </div>

          <div className="point-marker__info-block-item">
            <p className="point-marker__info-block-item-title">Адреса:</p>
            <p className="point-marker__info-block-item-info">{adress}</p>
          </div>
          <a
            href={`https://www.google.com/maps/dir//${latitude},${longitude}/@,31.29337,9z/`}
            className="point-marker__submit-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            Прокласти маршрут
          </a>
        </div>
      )}
      {isMobile && (
        <div className="point-marker__info-block-mobile-wrapper">
          <div className={`point-marker__info-block-mobile`}>
            <div className="point-marker__info-block-name-wrapper-mobile">
              <div className="point-marker__info-block-icon" style={{background: cluster.properties.iconUrl}}></div>

              <p className="point-marker__info-block-name-mobile">{name}</p>
            </div>

            <div className="point-marker__info-block-item-mobile">
              <p className="point-marker__info-block-item-title-mobile">
                Адреса:
              </p>
              <p className="point-marker__info-block-item-info-mobile">
                {adress}
              </p>
            </div>
             {category_id ? <div className="point-marker__info-block-item-mobile">
              <p className="point-marker__info-block-item-title-mobile">
                Категорія:
              </p>
              <p className="point-marker__info-block-item-info-mobile">
                {category_id}
              </p>
            </div> : <></>}
            {specialization ? <div className="point-marker__info-block-item-mobile">
              <p className="point-marker__info-block-item-title-mobile">
                Спеціалізація:
              </p>
              <p className="point-marker__info-block-item-info-mobile">
                {specialization}
              </p>
            </div> : <></>}
            {coverage ? <div className="point-marker__info-block-item-mobile">
              <p className="point-marker__info-block-item-title-mobile">
                Рівень покриття:
              </p>
              <p className="point-marker__info-block-item-info-mobile">
                {coverage}
              </p>
            </div> : <></>}
            <div className="point-marker__submit-button-wrapper">
            <button
            onClick={() => openAppoitDoctorPage()}
           className="point-marker__submit-button point-marker_appoint-button"
          >
            Створити заявку
          </button>
              <a
                href={`https://www.google.com/maps/dir//${latitude},${longitude}/@,31.29337,9z/`}
                className="point-marker__submit-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                Прокласти маршрут
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
