import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { TokenService } from '../../service/token/storage.service';

const PublicRoute = ({component: Component, restricted, showToast, showGreeting, ...rest}) => {
    return (
        <Route {...rest} render={props => (
            TokenService.isLogin() && restricted ?
                <Redirect to="/dashboard" />
            : <Component showToast={showToast} showGreeting={showGreeting} {...props} />
        )} />
    );
};

export default PublicRoute;