import { ActionType } from "../../const/const";

const initialState = {
  isMapFilterOpen: false,
  isOpenCar: false,
  isOpenPlain: false,
  isOpenGreenCard: false
};

export const control = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_MAPFILTER_OPEN:
      return {
        ...state,
        isMapFilterOpen: action.payload,
      };
    case ActionType.IS_OPEN_CAR:
      return {
        ...state,
        isOpenCar: action.payload,
        isOpenPlain: false,
        isOpenGreenCard: false
      }
      case ActionType.IS_OPEN_PLAIN:
        return {
          ...state,
          isOpenCar: false,
          isOpenPlain: action.payload,
          isOpenGreenCard: false
        }
        case ActionType.IS_OPEN_GREEN_CARD:
          return {
            ...state,
            isOpenCar: false,
            isOpenPlain: false,
            isOpenGreenCard: action.payload
          }
    default:
      return state;
  }
};
