import React from 'react';
import phoneIcon from '../../../assets/img/Caller.svg';
import connect from "react-redux/es/connect/connect";
import arrow from '../../../assets/img/arrow_forward.svg';
import { transformTelForCompany } from '../../validator/tel.validator';

function InsuranceCompany({ data }) {
       const insuranceData = data.insurance_information;

       const setFileUrl = function() {
            if(!data.files) return;

            let url;

            // eslint-disable-next-line
            data.files.map(item => {
                if(item.name === 'Програма') {
                    
                    url =  item.url;
                } 
            })
            
            return url;
        };
        
        return (
            <div className="persolan-area__conteiner broker__area">
                { 
                    insuranceData && 
                    <div className="personal__info personal__info__mobile">
                        <div className="top__header">
                            <div className="caption">Страхова компанія</div>
                        </div>
                        <div className="row align-center">
                            <div className="personal__info__avatar">
                                <img src={insuranceData.imageUrl} alt="company"/>
                            </div>
                            <div className="large__18 text ml10">{insuranceData.insurance_company}</div>
                        </div>
                        <div className="personal__info__desc">
                            <div className="row align-center space-between">
                                <div className="column">
                                    <span className="label">Зв'язок для допомоги:</span>
                                    <div className="desc phone">{transformTelForCompany(insuranceData.phoneCompany)}</div>
                                </div>
                                <a href={`tel:${transformTelForCompany(insuranceData.phoneCompany)}`}>
                                    <img src={phoneIcon} className="scale-icon" alt="tel"/>
                                </a>
                            </div>
                        </div>
                        {
                            setFileUrl() ?
                            <div className="personal__info__desc">
                                <a 
                                    href={setFileUrl()} 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    className="row align-center space-between company__file__link--parent"
                                >
                                    <span className="company__file__link">Додаткові контакти</span>
                                    <div className="company__arrow">
                                        <img 
                                            src={arrow} 
                                            className="pointer scale-icon" 
                                            alt="arrow"
                                        />
                                    </div>
                                </a>
                            </div>
                            :
                            null
                        }

                    </div>
                }
            </div>
        )
}

function mapStateToProps(state) {
    return {
        data: state.user.userData,
    }
}

export default connect(mapStateToProps)(InsuranceCompany);