import { combineReducers } from "redux";
import { userData } from './userData';

const allReducers = combineReducers({
    userData,
});

const user = (state, action) => {
    return allReducers(state, action);
};

export default user;