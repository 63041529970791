import React, { Component } from 'react';
import logo from '../../assets/img/logo_color.png';
import { TokenService } from '../../service/token/storage.service';
import ApiService from '../../service/api/api.service';
import { trackPromise } from 'react-promise-tracker';

class SetPassword extends Component {
    state = {
        password: '',
        repeatPass: '',
        isFormError: false,
        passwordErrorText: '',
        repeatPassErrorText: ''
    };

    componentDidMount() {
        if(TokenService.getTempToken() === null) {
            this.props.history.push('/login');
            ApiService.removeHeader();
        } else {
            ApiService.setTempHeader();
        }
    }

    handleInput = ({target}) => {
        this.setState({[target.name]: target.value})
    };

    submitPasswordForm = () => {
        let pass = this.state.password,
            repeat = this.state.repeatPass;

        if(pass.length === 0 || repeat.length === 0) {
            this.setState({
                isFormError: true,
                passwordErrorText: "Поле обов'язкове для заповнення",
                repeatPassErrorText: "Поле обов'язкове для заповнення",
            });
        } else if(pass.length < 8) {
            this.setState({
                isFormError: true,
                passwordErrorText: 'Пароль повинен містити символи: А, а, 0-9 і бути більше 8 символів',
            });
        } else if(pass !== repeat) {
            this.setState({
                isFormError: true,
                repeatPassErrorText: 'Паролі повинні співпадати',
            });
        } else if(pass === repeat) {
            this.setState({isFormError: false});
            let data =  {
                query: `query {
                        authNewPassword(password: "${pass}", password_repeat: "${repeat}") {
                            success,
                            message,
                            access_token
                          }
                    }`
                };

                trackPromise(
                    ApiService.post('/cabinet', data)
                    .then(response => {
                        const { data } = response.data;
    
                        if(data.authNewPassword.success) {
                            TokenService.removeTempToken();
                            TokenService.saveToken(data.authNewPassword.access_token);
                            ApiService.removeHeader();
                            ApiService.setHeader();
                            this.props.showGreeting();
                            this.props.history.push('/dashboard');
    
                        } else {
                            this.setState({
                                isFormError: true,
                                repeatPassErrorText: data.authNewPassword.message,
                            })
                        }
                    })
                    .catch(err => console.error(err))
                );
        }
    };

    clearError = () => {
        this.setState({isFormError: false})
    };

    handleKeydown = (event) => {
        if(event.keyCode === 13) {
            this.submitPasswordForm();
        }
    };

    render() {
        const { 
            isFormError,
            passwordErrorText,
            repeatPassErrorText
         } = this.state;

        return(
            <div className="check_in">
                <div className="check_in__logo">
                    <img src={logo} alt="logo"/>
                </div>
                <h1 className="caption">Створення пароля</h1>
                <p className="desc">Стандартний пароль потрібно змінити.</p>
                <p className="desc">Створіть новий пароль в полях нижче</p>
                <form className={`check_in__form password__form ${isFormError ? 'error' : ''}`}>
                    <div className="check_in__form__row">
                        <label>Новий пароль</label>
                        <input 
                            type="password" 
                            name="password" 
                            required 
                            onChange={this.handleInput}
                            onFocus={this.clearError}
                        />
                        <div className="help__error help_desc">{passwordErrorText}</div>
                    </div>
                    <div className="check_in__form__row">
                        <label>Повторіть новий пароль</label>
                        <input 
                            type="password" 
                            required 
                            name="repeatPass" 
                            onChange={this.handleInput}
                            onFocus={this.clearError}
                            onKeyDown={this.handleKeydown}
                        />
                        <div className="help__error">{repeatPassErrorText}</div>
                    </div>
                    <button type="button" onClick={this.submitPasswordForm} className="button button__success">Створити пароль</button>
                </form>
          </div>
        )
    }
}

export default SetPassword;