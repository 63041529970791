import React, { PureComponent } from "react";
import Header from "./layouts/header";
import Main from "./layouts/main";
import Footer from "./layouts/footer";
import MobileMenu from "./layouts/mobileMenu";
import { Greeting } from "./layouts/greeting";
import { trackPromise } from "react-promise-tracker";
import { getLayouts } from "../../actions/layouts/getLayouts";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import ApiService from "../../service/api/api.service";
import ToggleChatBtn from "./layouts/toggleChatBtn";
import MobileDashBoard from "./layouts/mobile-dashboard/mobileDashboard";
import { getUserData } from "../../actions/user/getUserData";
import { setIsAddingProfile } from "../../actions/user/setIsAddingProfile";
import { setLpu } from "../../actions/lpu/setLpu";
import { setCurrentLpuList } from "../../actions/lpu/setCurrentLpuList";
import { setLpuCategories } from "../../actions/lpu/setLpuCategories";
import { setRefundTickets } from "../../actions/refund/setRefundTickets";
import { enableNotifications } from "../../actions/api-actions";
import { setVisits } from "../../actions/visits/setVisits";
import { Redirect } from "react-router-dom";
import {
  TokenService,
  ProfileService,
  UserDataService,
} from "../../service/token/storage.service";
import {
  lpuAdapter,
  refundTicketsListAdapter,
  visitListAdapter,
} from "../../utils/utils";

class Dashboard extends PureComponent {
  state = {
    mobMenuClass: "",
    chatClass: "",
    chatBtnClass: "",
    isMobileWidth: window.innerWidth < 800,
  };

  componentWillUnmount() {
    TokenService.getFirstData(false);
  }

  componentWillMount() {
    console.log({ componentWillMount: "componentWillMount" });
    TokenService.setPreviosToken();
    if (TokenService.getPreviosToken() && !TokenService.getToken()) {
      TokenService.saveToken(TokenService.getPreviosToken());
      ApiService.setHeader();
    }
  }

  componentDidMount() {
    console.log({
      "TokenService.getPreviosToken()": TokenService.getPreviosToken(),
    });

    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }

    let requestData = {
      query: `query {
                    header {
                    phone
                    email
                    menu
                    },
                    socialNetworks {
                    name
                    link
                    icon
                    },
                    footer {
                    phone
                    email
                    address_code
                    address_location
                    address_position
                    images_title
                    down_text_1
                    down_text_2
                    footerImages {
                        path
                    }
                    },                   
                                       
                    lpuCategories(access_token: "${TokenService.getToken()}") {
                        id,
                        name,
                        icon
                    },
                    lpuSpecializations(access_token: "${TokenService.getToken()}") {
                        id,
                        name,
                        icon
                    },
                    refundRequests(access_token: "${TokenService.getToken()}") {
                      id,
                      status,
                      iban,
                      created_at,
                      refund_amount,
                      refund_date,
                      file_order,
                      files_medical_return,
                      files_act,
                      files_payment_receipt,
                      files_copy_passport,
                      files_copy_inn,
                      files_copy_insurance,  
                    },
                    historyVisits(access_token: "${TokenService.getToken()}") {
                      id,
                      date,
                      contract_number,
                      program,
                      medical_facility,
                      medical_care_general,
                      medical_care_detailed,
                      city,
                      price,
                      created_at
                    },                                                                          
                    user(access_token: "${TokenService.getToken()}") {                     
                        username,
                        id,
                        phone,
                        birth_date,
                        address,
                        polis,
                        card_number,
                        newsletters {
                            id,
                            title,
                            message,
                            buttons,
                            date_public,
                            image,
                            readed
                        },
                        image,
                        polis_vzk,
                        lpu {
                          name,
                          category_id,                        
                          full_name,
                          age_category,
                          ownership,
                          coverage,
                          specialization,
                          lat,
                          lng,
                          title,
                          link,
                          address
                      },
                                            
                                                             
                        messages {
                            chat_id,
                            user_id,
                            message,
                            time_created,
                            file,
                            file_name,
                            file_size
                        },
                        insurance_information {
                            insurance_program,
                            insurance_company
                            insurer,
                            project,
                            active_contract_from,
                            active_contract_until,
                            imageUrl,
                            phoneCompany,
                            additionalPhonesCompany,
                            emailCompany,
                            excelFile {
                              name,
                              path,
                              url
                            }
                          },
                        broker {
                          name,
                          email,
                          id,
                          phone,
                          last_activity,
                          imageUrl
                        },
                           files {
                             name,
                             path,
                             url
                           },
                           sharedFiles {
                            name,
                            path,
                            url
                          }
                         }
              }`,
    };
    let data;

    if (
      localStorage.getItem("usersData") !== null &&
      localStorage.getItem("usersData") !== "undefined"
    ) {
      const userInformationInStorage = JSON.parse(
        localStorage.getItem("usersData")
      );

      if (userInformationInStorage) {
        data = userInformationInStorage;

        this.fillInformation(data);
      }
    }
    console.log("New Version");
    trackPromise(
      ApiService.postChachedData("/cabinet", requestData)
        .then((response) => {
          data = response.data;
          console.log(data);
          UserDataService.removeUserData();
          if (!data.user?.id) {
            const multyProfiles = ProfileService.getMultyProfiles();
            const nextProfile = multyProfiles.find(
              (item) => item.id !== data.id
            );
            if (nextProfile) {
              ProfileService.deleteMultiProfile(data.id);
              TokenService.removeToken();
              ApiService.removeHeader();
              TokenService.removeChatData();
              UserDataService.removeUserData();
              TokenService.saveToken(nextProfile.token);
              ApiService.setHeader();
              window.location.reload(false);
            }
            if (multyProfiles.length > 0) {
              ProfileService.deleteMultiProfile(data.id);
            }
            TokenService.removeToken();
            TokenService.removePreviosToken();
            TokenService.removeChatData();
            ApiService.removeHeader();
            UserDataService.removeUserData();
            window.location.reload(false);
          }
          const dataMin = {
            ...data,
            user: { ...data.user, lpu: data.user.lpu?.slice(0, 2000) },
          };
          try {
            localStorage.setItem("usersData", JSON.stringify(dataMin));
          } catch (err) {
            console.log(err.message);
          }

          this.fillInformation(data);
          console.log({ data: data });
          TokenService.getFirstData(true);
        })
        .catch((err) => {
          console.error({ ERROR: err });
          TokenService.getFirstData(true);
        })
    );

    window.addEventListener("resize", () => {
      if (window.innerWidth < 800) {
        this.setState({ isMobileWidth: true });
      }

      if (window.innerWidth >= 800) {
        this.setState({ isMobileWidth: false });
      }
    });
  }

  fillInformation(data) {
    const { getLayouts } = this.props;
    getLayouts(data);

    if (data && data.user && data.user.broker) {
      this.props.getUserData(data.user);

      let chat_data = {
        user_id: data.user.id,
        broker_id: data.user.broker.id,
      };

      TokenService.saveChatData(chat_data);
    } else if (data && data.user && !data.user.broker) {
      console.log({ data });
      console.log({ "data.user": data.user });
      console.log({ "!data.user.broker": data.user.broker });
      this.props.history.push("/trouble");
    }

    if (data.user.lpu && data.lpuCategories) {
      let adaptedLpuList = data.user.lpu.slice().map((item) => {
        return lpuAdapter(item);
      });

      this.props.setLpuCategories(data.lpuSpecializations);

      this.props.setLpu(adaptedLpuList);
      this.props.setCurrentLpuList(adaptedLpuList);
    }

    if (data.lpuCategories) {
      this.props.setLpuCategories(data.lpuSpecializations.slice());
    }

    if (data.refundRequests) {
      const adaptedRefundList = data.refundRequests.map((item) => {
        return refundTicketsListAdapter(item);
      });

      this.props.setRefundTickets(adaptedRefundList);
    }

    if (data.historyVisits) {
      const adaptedVisitList = data.historyVisits.map((visit) => {
        return visitListAdapter(visit);
      });

      this.props.setVisits(adaptedVisitList);
    }
  }

  setActiveBtn = () => {
    this.setState({ chatBtnClass: "active" });
  };

  disabledBtn = () => {
    this.setState({ chatBtnClass: "" });
  };

  closeChat = () => {
    this.setState({ chatClass: "" });
    document.querySelector("body").classList.remove("no__scroll-mobile");
  };

  openChat = () => {
    this.setState({ chatClass: "active" });
    document.querySelector("body").classList.add("no__scroll-mobile");
  };

  toggleChat = () => {
    if (this.state.chatClass === "active") {
      this.setState({ chatClass: "" });
      document.querySelector("body").classList.remove("no__scroll-mobile");
    } else {
      document.querySelector("body").classList.add("no__scroll-mobile");
      this.setState({ chatClass: "active" });
    }
  };

  openMobileMenu = () => {
    this.setState({ mobMenuClass: "open" });
  };

  handleResize = () => {};

  closeMobileMenu = () => {
    this.setState({ mobMenuClass: "" });
  };

  showNotification() {
    new Notification("Hey");
  }

  render() {
    const { mobMenuClass } = this.state;
    const { greeting, showToast } = this.props;

    if (!TokenService.getToken()) {
      return <Redirect to={`/login`} />;
    }

    if (this.state.isMobileWidth) {
      return <MobileDashBoard showToast={showToast} />;
    }

    return (
      <div className="l-wrap">
        <Greeting greeting={greeting} />
        <Header openMobileMenu={this.openMobileMenu} />
        <Main
          chatClass={this.state.chatClass}
          showToast={showToast}
          closeChat={this.closeChat}
          setActiveBtn={this.setActiveBtn}
          openChat={this.openChat}
        />
        <MobileMenu
          open={mobMenuClass}
          closeMobileMenu={this.closeMobileMenu}
        />
        <Footer showToast={this.showToast} />
        <ToggleChatBtn
          toggleChat={this.toggleChat}
          chatClass={this.state.chatClass}
          chatBtnClass={this.state.chatBtnClass}
          disabledBtn={this.disabledBtn}
        />
      </div>
    );
  }
}

function matchDispatchToProps(dispath) {
  return bindActionCreators(
    {
      getLayouts: getLayouts,
      getUserData: getUserData,
      setLpu: setLpu,
      setCurrentLpuList: setCurrentLpuList,
      setRefundTickets: setRefundTickets,
      setLpuCategories: setLpuCategories,
      setVisits: setVisits,
      setIsAddingProfile: setIsAddingProfile,
    },
    dispath
  );
}

function mapStateToProps(state) {
  return {
    userData: state.user.userData,
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(Dashboard);
