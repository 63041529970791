import React from "react";
import { TICKET_STATUS, DOCUMENT_TYPES } from "../../../../../const/const";
import { connect } from "react-redux";
import { setMapFilterState } from "../../../../../actions/control/setMapFilterState";

export function RefundTicket({ ticket, onOpenPayFormHandler, openFilePage }) {
  const {
    status,
    date,
    ticketNumber,
    iban,
    fileOrder,
    filesAct,
    filesCopyInn,
    filesCopyInsurance,
    filesCopyPassport,
    filesMedicalReturn,
    filesPayMentReceipt,
  } = ticket;

  const onOpenFilesPageHandler = (evt, ticketNumber) => {
    evt.preventDefault();
    openFilePage(ticketNumber);
  };

  return (
    <div className="refund-ticket">
      <h2 className="refund-ticket__title">{TICKET_STATUS[status]}</h2>
      <div className="refund-ticket__info-block">
        <div className="refund-ticket__info-block-name">№Заявки:</div>
        <div className="refund-ticket__info-block-value">{ticketNumber}</div>
      </div>
      <div className="refund-ticket__info-block">
        <div className="refund-ticket__info-block-name">
          Дата створення заявки:
        </div>
        <div className="refund-ticket__info-block-value">{date}</div>
      </div>
      <div
        onClick={(evt) => onOpenFilesPageHandler(evt, ticketNumber)}
        className="refund-ticket__info-block refund-ticket__info-block"
      >
        <div className="refund-ticket__info-block-name">
          Перелік доданих до заявки вкладень (доданих файлів):
        </div>
        <div className="refund-ticket__info-block-value-files">
          {fileOrder.length > 0 && (
            <span className="refund-ticket__file-name-block">
              {DOCUMENT_TYPES.fileOrder}({1})
            </span>
          )}
          {filesAct.length > 0 && (
            <span className="refund-ticket__file-name-block">
              {DOCUMENT_TYPES.filesAct}({filesAct.length})
            </span>
          )}
          {filesCopyInn.length > 0 && (
            <span className="refund-ticket__file-name-block">
              {DOCUMENT_TYPES.filesCopyInn}({filesCopyInn.length})
            </span>
          )}
          {filesCopyInsurance.length > 0 && (
            <span className="refund-ticket__file-name-block">
              {DOCUMENT_TYPES.filesCopyInsurance}({filesCopyInsurance.length})
            </span>
          )}
          {filesCopyPassport.length > 0 && (
            <span className="refund-ticket__file-name-block">
              {DOCUMENT_TYPES.filesCopyPassport}({filesCopyPassport.length})
            </span>
          )}
          {filesMedicalReturn.length > 0 && (
            <span className="refund-ticket__file-name-block">
              {DOCUMENT_TYPES.filesMedicalReturn}({filesMedicalReturn.length})
            </span>
          )}
          {filesPayMentReceipt.length > 0 && (
            <span className="refund-ticket__file-name-block">
              {DOCUMENT_TYPES.filesPayMentReceipt}({filesPayMentReceipt.length})
            </span>
          )}
        </div>
      </div>
      <div className="refund-ticket__info-block">
        <div className="refund-ticket__info-block-name">IBAN:</div>
        <div className="refund-ticket__info-block-value">{iban}</div>
      </div>
      <div className="refund-ticket__info-block-button-wrapper">
        <button
          disabled={status === TICKET_STATUS.canselled}
          className="refund-ticket__info-block-button"
          onClick={(evt) => onOpenPayFormHandler(evt, ticketNumber)}
        >
          Виплачено
        </button>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setMapFilter(value) {
    dispatch(setMapFilterState(value));
  },
});

export default connect(null, mapDispatchToProps)(RefundTicket);
