const initialState = {};

export const layouts = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_LAYOUTS':
            return {
                    ...state,
                    ...action.payload
                 }; 
        default:
            return state;
    }
};