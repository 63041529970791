import React from "react";
import { getFileImageMobile } from "../../../../../utils/utils";

function download(url) {
  var element = document.createElement("a");
  element.setAttribute("href", url);
  element.setAttribute("download", "rrr");
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function MainInformationFileBlock({ title, files, onClickFile }) {
  const onClickFileHandler = (url) => {
    //window.open(url, "_blank");
    onClickFile(url);
  };

  return (
    <>
      <h2 className="main-information-mobile_files-block__title">{title}</h2>
      {files !== null && (
        <ul className="main-information-mobile_list">
          {files.map((file) => {
            return (
              <li
                onClick={() => onClickFileHandler(file.url)}
                className="main-information-mobile_list__item"
                key={file.name}
              >
                <img
                  className="main-information-mobile_list__item-image"
                  src={getFileImageMobile(file.url.split(".").pop())}
                  alt="file"
                />
                <p className="main-information-mobile_list__item-name">
                  {file.name}
                </p>
                <div className="main-information-mobile__watch-icon">
                  <svg
                    width="17"
                    height="10"
                    viewBox="0 0 17 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.5 0C4.85714 0 1.82143 2.2225 0 5C1.82143 7.77688 4.85714 10 8.5 10C12.1429 10 15.1786 7.77688 17 5C15.1786 2.2225 12.1429 0 8.5 0ZM4.77518 7.79562C3.55179 7.15187 2.42979 6.205 1.50329 5C2.42979 3.795 3.55179 2.8475 4.77518 2.20438C4.21843 2.98938 3.88571 3.95375 3.88571 5C3.88571 6.04625 4.21843 7.01062 4.77518 7.79562ZM8.5 8.75C6.49096 8.75 4.85714 7.06812 4.85714 5C4.85714 2.93187 6.49096 1.25 8.5 1.25C10.509 1.25 12.1429 2.93187 12.1429 5C12.1429 7.06812 10.509 8.75 8.5 8.75ZM12.2248 7.79562C12.7816 7.01062 13.1143 6.04625 13.1143 5C13.1143 3.95375 12.7816 2.98938 12.2248 2.20438C13.4488 2.8475 14.5702 3.79437 15.4967 5C14.5702 6.205 13.4482 7.15187 12.2248 7.79562Z"
                      fill="#AFAFAF"
                    />
                    <path
                      d="M8.5 2.81201C8.17579 2.81201 7.87221 2.89264 7.59718 3.02639C7.90561 3.13951 8.12843 3.43951 8.12843 3.79639C8.12843 4.24951 7.77204 4.61701 7.33125 4.61701C6.98457 4.61701 6.69314 4.38826 6.58325 4.07014C6.45332 4.35326 6.375 4.66576 6.375 4.99951C6.375 6.20826 7.32639 7.18701 8.5 7.18701C9.67421 7.18701 10.625 6.20826 10.625 4.99951C10.625 3.79139 9.67421 2.81201 8.5 2.81201Z"
                      fill="#AFAFAF"
                    />
                  </svg>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
}
