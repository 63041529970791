import { ActionType } from "../../const/const";
import { sortPaidTickets } from "../../utils/utils";

const SUCCESS_STATUS = "success";

const initialState = {
  refundTickets: null,
  notPaidTickets: null,
  paidTickets: null,
};

export const refund = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_REFUND_TICKETS:
      return {
        ...state,
        refundTickets: action.payload,
        notPaidTickets: action.payload.slice().filter((ticket) => {
          return ticket.status !== SUCCESS_STATUS;
        }),
        paidTickets: action.payload
          .slice()
          .filter((ticket) => {
            return ticket.status === SUCCESS_STATUS;
          })
          .sort(sortPaidTickets),
      };
    default:
      return state;
  }
};
