import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

class ModalCall extends Component {
    render() {
        const {
            active, 
            closeModalCall
        } = this.props;

        return (
            <div className={`modal__wrap call ${active}`}>
                <div className="modal__call">
                <div className="modal__call__head">
                    <div className="caption">Заявкана обратный звонок</div>
                    <FontAwesomeIcon 
                        icon={faTimes} 
                        className="close__modal" 
                        onClick={closeModalCall}
                    />
                </div>
                <form className="modal__call__body">
                    <div className="modal__call__body__row">
                        <label>Ваше ФИО</label>
                        <input type="text" required placeholder="name"/>
                        <div className="help__error">error</div>
                    </div>
                    <div className="modal__call__body__row">
                        <label>Ваш контактный номер</label>
                        <input type="phone" required placeholder="+38 (0  )"/>
                        <div className="help__error">error</div>
                    </div>
                    <button className="button button__success">Отправить заявку</button>
                </form>
                </div>
            </div>
        )
    }
}

export default ModalCall;