import React, { Component } from "react";
import defaultPhoto from "../../../assets/img/default_photo.png";
import editIcon from "../../../assets/img/editIcon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import connect from "react-redux/es/connect/connect";
import ApiService from "../../../service/api/api.service";
import {
  TokenService,
  ProfileService,
  UserDataService,
} from "../../../service/token/storage.service";
import { trackPromise } from "react-promise-tracker";
import { transformTelNumberReverse } from "../../validator/tel.validator";
import AddProfileButton from "./AddProfileButton";
import { Redirect } from "react-router-dom";
import DeleteMultyProfileButton from "../../common/buttons/DeleteMultyProfileButton";

import "./userInfo.scss";

const arrayTest = [1, 2, 3, 4];

class UserInfo extends Component {
  state = {
    isMultiLoginMenuOpen: false,
    multyProfiles: ProfileService.getMultyProfiles(),
  };

  changeUserPhoto = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    let { showToast } = this.props;

    if (!file.type.includes("image")) {
      showToast("error", "Будь ласка, виберіть зображення");
      return;
    }

    reader.onloadend = () => {
      let data = {
        query: `mutation {
                    user(access_token: "${TokenService.getToken()}") {
                        update(file: "${reader.result}") {
                            success,
                            message,
                            image
                        }
                    }
                }`,
      };

      trackPromise(
        ApiService.post("/cabinet", data)
          .then((response) => {
            const { data } = response.data;
            const { changePhotoUrl } = this.props;

            if (data.user.update.success) {
              changePhotoUrl(data.user.update.image);
              showToast("success", data.user.update.message);
            } else {
              showToast("success", data.user.update.message);
            }
          })
          .catch((err) => console.error(err))
      );
    };

    reader.readAsDataURL(file);
  };

  openMultiLoginMenu = () => {
    this.setState({
      isMultiLoginMenuOpen: !this.state.isMultiLoginMenuOpen,
    });
  };

  refreshPage = () => {
    window.location.reload(false);
  };

  loadMultiprofile = (profile) => {
    ProfileService.addCurrentProfile({
      id: this.props.data.id,
      userName: this.props.data.username,
      image: this.props.data.image,
    });
    TokenService.removeChatData();
    UserDataService.removeUserData();
    TokenService.saveToken(profile.token);

    this.refreshPage();

    return <Redirect to={`/dashboard`} />;
  };

  onDeleteMultiProfileHandler = (id) => {
    this.setState({
      multyProfiles: this.state.multyProfiles.filter((item) => item.id !== id),
    });
    ProfileService.deleteMultiProfile(id);
  };

  render() {
    const userData = this.props.data;
    const { openModalChangeTel } = this.props;

    const { image } = this.props.data;

    return (
      <div
        className={`persolan-area__conteiner ${
          this.state.isMultiLoginMenuOpen
            ? "persolan-area__conteiner--multilogin"
            : ""
        }`}
      >
        <div className="personal__info personal__info__mobile">
          <div
            className={`row align-center ${
              this.state.isMultiLoginMenuOpen
                ? "persolan-area__conteiner__multilogin__prsonal__wraper"
                : ""
            }`}
            style={{ cursor: "pointer", position: "relative" }}
          >
            <div
              onClick={() => this.openMultiLoginMenu()}
              className="personal__info__user__avatar"
            >
              <div className="personal__info__avatar">
                <img src={image ? image : defaultPhoto} alt="user" />
              </div>
              <label htmlFor="photoInput">
                <FontAwesomeIcon size="lg" color="#FFFFFF" icon={faCamera} />
              </label>
              <input
                hidden
                id="photoInput"
                type="file"
                onChange={this.changeUserPhoto}
              />
            </div>
            <div
              onClick={() => this.openMultiLoginMenu()}
              className="large__18 text ml20"
            >
              {userData.username}
            </div>
            <div
              onClick={() => this.openMultiLoginMenu()}
              className="personal__info__open-menu-arrow"
            >
              {!this.state.isMultiLoginMenuOpen && (
                <svg
                  width="21"
                  height="13"
                  viewBox="0 0 21 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.45023 11.7904L0.450409 2.60307C0.150415 2.29683 0.000417709 1.99059 0.000417709 1.53122C0.000417709 1.07185 0.150415 0.76561 0.450409 0.459366C1.0504 -0.153122 1.95038 -0.153122 2.55037 0.459366L10.5002 8.57483L18.4501 0.459366C19.05 -0.153122 19.95 -0.153122 20.55 0.459366C21.15 1.07185 21.15 1.99059 20.55 2.60307L11.5502 11.7904C10.9502 12.4029 10.0502 12.4029 9.45023 11.7904Z"
                    fill="#AFAFAF"
                  />
                </svg>
              )}
              {this.state.isMultiLoginMenuOpen && (
                <svg
                  width="21"
                  height="13"
                  viewBox="0 0 21 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5498 0.459123L20.5496 9.64644C20.8496 9.95268 20.9996 10.2589 20.9996 10.7183C20.9996 11.1777 20.8496 11.4839 20.5496 11.7901C19.9496 12.4026 19.0496 12.4026 18.4496 11.7901L10.4998 3.67468L2.54995 11.7901C1.94996 12.4026 1.04998 12.4026 0.449991 11.7901C-0.149997 11.1777 -0.149997 10.2589 0.449991 9.64644L9.44981 0.459123C10.0498 -0.153365 10.9498 -0.153365 11.5498 0.459123Z"
                    fill="#AFAFAF"
                  />
                </svg>
              )}
            </div>
          </div>
          {!this.state.isMultiLoginMenuOpen && (
            <div className="personal__info__desc number__policy__mobile">
              <div className="row align-center space-between">
                <div className="column">
                  <span className="label">Номер телефону:</span>
                  {userData.phone && (
                    <div className="desc number__policy number__phone__change">
                      {transformTelNumberReverse(userData.phone)}
                    </div>
                  )}
                </div>
                <img
                  className="pointer scale-icon"
                  src={editIcon}
                  alt="edit"
                  onClick={openModalChangeTel}
                />
              </div>
            </div>
          )}
          {!this.state.isMultiLoginMenuOpen && (
            <div className="personal__info__desc__mobile__row">
              <div className="personal__info__desc col__mobile">
                <span className="label">Дата народження:</span>
                <div className="desc date_birth">{userData.birth_date}</div>
              </div>
            </div>
          )}
          {!this.state.isMultiLoginMenuOpen && (
            <div className="personal__info__desc">
              <span className="label">Адреса:</span>
              <div className="desc adress">{userData.address}</div>
            </div>
          )}
          {!this.state.isMultiLoginMenuOpen && (
            <div className="personal__info__desc number__policy__mobile">
              <span className="label">№ ІНН:</span>
              <div className="desc number__policy">{userData.polis}</div>
            </div>
          )}
          {!this.state.isMultiLoginMenuOpen && (
            <div className="personal__info__desc number__policy__mobile">
              <span className="label">№ страхового поліса</span>
              <div className="desc number__policy">{userData.card_number}</div>
            </div>
          )}
          {this.state.isMultiLoginMenuOpen && (
            <div className="personal__info__desc__mobile__row multilogin-menu-container">
              {this.state.multyProfiles.map((item, index) => {
                if (item.id === userData.id) {
                  return null;
                }
                return (
                  <div
                    onClick={() => this.loadMultiprofile(item)}
                    className={`personal__info personal__info__mobile multilogin-menu_item ${
                      index < this.state.multyProfiles.length - 1
                        ? "multilogin-menu__separator-line"
                        : ""
                    }`}
                    key={item.id}
                  >
                    <div className="row align-center">
                      <div className="personal__info__user__avatar">
                        <div className="personal__info__avatar">
                          <img
                            src={item.image ? item.image : defaultPhoto}
                            alt="user"
                          />
                        </div>
                      </div>
                      <div className="large__18 text ml20">{item.userName}</div>
                    </div>
                    <DeleteMultyProfileButton
                      onClick={() => this.onDeleteMultiProfileHandler(item.id)}
                    />
                  </div>
                );
              })}
              <div className="multilogin-menu__add-proffile-button-container">
                <div className="multilogin-menu__add-proffile-button-wrapper">
                  <AddProfileButton isMobile={false} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.user.userData,
  };
}

export default connect(mapStateToProps)(UserInfo);
