import React from "react";
import { IoTrashOutline } from "react-icons/io5";

const DeleteMultyProfileButton = ({ onClick }) => {
  const onClickHandler = () => {
    onClick();
  };

  return null;
  // <div className="multilogin-menu__trash-button-wrapper">
  //   <div
  //     onClick={() => onClickHandler()}
  //     className="multilogin-menu__trash-button"
  //   >
  //     <IoTrashOutline name="trash-outline"></IoTrashOutline>
  //   </div>
  // </div>
};

export default DeleteMultyProfileButton;
