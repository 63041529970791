import React, { useState, useEffect } from "react";
// import GoogleDocsViewer from "react-google-docs-viewer";
import { pdfjs } from "react-pdf";
import { FILE_TYPES } from "../../../../../const/const";
// import ApiService from "../../../../../service/api/api.service";
import { isIos } from "../../../../../utils/utils";
import { MobilePDFReader } from "reactjs-pdf-reader";
import { Document, Page } from 'react-pdf';
import PinchToZoom from 'react-pinch-and-zoom';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
let pageArr = [];

let isIOS = isIos();

export const FileOpener = ({ onClose, fileUrl }) => {
  const [isOffiseDoc, setIsOffiseDoc] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [isPdf, setIsPdf] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isPdfLoaded, setIsPdfLoaded] = useState(false);


  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setIsPdfLoaded(true);
    if (numPages && pageArr.length === 0) {
      for (let i = 0; i < numPages; i++) {
        pageArr.push(i + 1);
      }
    }
  }
  const onCloseFormHandler = (evt) => {
    evt.preventDefault();
    onClose();
  };

  // const requestUrlGoogleApi = `https://docs.google.com/viewer?url=${encodeURI(
  //   fileUrl
  // )}&embedded=true`;

  // const requestUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURI(
  //   fileUrl
  // )}`;

  const getFileType = (url) => {
    switch (url.split(".").pop()) {
      case FILE_TYPES.PNG:
        setIsImage(true);
        setIsOffiseDoc(false);
        setIsPdf(false);
        return;
      case FILE_TYPES.JPEG:
        setIsImage(true);
        setIsOffiseDoc(false);
        setIsPdf(false);
        return;
      case FILE_TYPES.JPG:
        setIsImage(true);
        setIsOffiseDoc(false);
        setIsPdf(false);
        return;
      case FILE_TYPES.PDF:
        setIsImage(false);
        setIsOffiseDoc(false);
        setIsPdf(true);
        return;
      case FILE_TYPES.DOCX:
        setIsImage(false);
        setIsOffiseDoc(true);
        setIsPdf(false);
        return;
      case FILE_TYPES.XLSX:
        setIsImage(false);
        setIsOffiseDoc(true);
        setIsPdf(false);
        return;
      default:
        setIsImage(false);
        setIsOffiseDoc(true);
        setIsPdf(false);
        return;
    }
  };

  useEffect(() => {
    getFileType(fileUrl);

    return () => {
      pageArr = [];
    };
  }, [getFileType]);

  return (
    <>
      <div className="file-opener__wrapper">
        <div className="pay-form-title-wrapper__mobile file-opener__title">
          <button
            onClick={(evt) => onCloseFormHandler(evt)}
            className="pay-form-back-button-mobile"
          >
            <svg
              width="27"
              height="17"
              viewBox="0 0 27 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 8.5L26 8.5"
                stroke="#519025"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M1 8.50002L8.40592 1"
                stroke="#519025"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M1 8.5L8.40592 16"
                stroke="#519025"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
          <h2 className="pay-form__title-mobile">Перегляд файлу</h2>
        </div>

        {/* {isPdf && (
          <iframe
            src={requestUrlGoogleApi}
            style={{ width: "100%", minHeight: "100vh" }}
          ></iframe>
        )} */}

        {isOffiseDoc && (<>
          <div className="download-block">
            <p className="download-block-text">Файл буде завантажено на ваш девайс. Якщо завантаження не почалося автоматично - натисніть сюди</p>
            <a href={fileUrl} download className="download-block-link">Завантажити файл</a>
          </div>
          <iframe
            src={fileUrl}
            style={{ width: "30%", minHeight: "30vh" }}
          ></iframe>
        </>
        )}
        

          {/* {isPdf && (
          <iframe
            src={fileUrl}
            style={{ width: "100%", minHeight: "100vh" }}
          ></iframe>
        )} */}

        {/* {!isImage && (
          <iframe
            src={fileUrl}
            style={{ width: "100%", minHeight: "80vh" }}
          ></iframe>
        )} */}

        {isImage && <img src={fileUrl} style={{ minHeight: "100vh" }} />}

        {isPdf && !isPdfLoaded && (
          <PinchToZoom>
                    <Document
              file={encodeURI(fileUrl)}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page width={100} scale={1.0} pageNumber={1} wrap={true}/>
            </Document>
          </PinchToZoom>
        )}

        {isPdf && isPdfLoaded && pageArr !== 0 && (
          <PinchToZoom>
            <Document
              file={encodeURI(fileUrl)}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {pageArr.map((item, index) => {
                return <Page pageNumber={item} wrap={true} key={index}/>;
              })}
            </Document>
          </PinchToZoom>
        )}

        {/* {isPdf && isIOS && (
          <iframe
            src={fileUrl}
            style={{ width: "100%", minHeight: "100vh" }}
            target="_parent"
          ></iframe>
        )} */}

        {/* {isPdf && (
          <div
            style={{
              overflow: "scroll",
              height: window.innerHeight,
            }}
          >
            <MobilePDFReader 
            url={encodeURI(fileUrl)} 
            isShowHeader={false}
        />
          </div>
        )} */}

        {/* <iframe
        src={requestUrl}
        style={{ width: "100%", minHeight: "100vh" }}
      ></iframe> */}
        {/* <img src={fileUrl} style={{ width: "100%", minHeight: "100vh" }} /> */}
        {/* <embed
        src={encodeURI(fileUrl)}
        style={{ width: "100%", minHeight: "100vh" }}
        type="application/pdf"
      /> */}
        {/* <object
        data="https://tbtadm.tbt-broker.com/uploads/refund-files/NQ73w-t8lIA0K1Td_mKu91OGSm5m3v3w.pdf"
        type="application/pdf"
        width="100%"
        height="100%"
      ></object> */}

        {/* <GoogleDocsViewer
        width="100%"
        height="100vh"
        fileUrl={encodeURI(
          "https://tbtadm.tbt-broker.com/files/shared/%D0%97%D0%B0%D1%8F%D0%B2%D0%B0%20%D0%BD%D0%B0%20%D0%B2%D1%96%D0%B4%D1%88%D0%BA%D0%BE%D0%B4%D1%83%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F%20iT.Artel.pdf"
        )}
      /> */}
      </div>
    </>
  );
};
