import { ActionType } from "../../const/const";
import noneCategoryIcon from "../../assets/img/none-category-marker.svg";

const initialState = {
  lpu: null,
  currentLpu: [],
  lpuCategories: [],
  filteredCategories: [],
};

const SPECIALIZATION_COLORS = {
  "Всі спеціалізації": '#FF0000',
  "Амбулатория": '#00FF00',
  "Стаціонар": '#0000FF',
  "Діагностика": '#FFFF00',
  "Стоматологія": '#c2ffc2',
  "Аптека": '#00FFFF',
  "Ведення вагітності та пологи": '#FF00FF',
  "Оптика": '#228B22',
  "Оздоровлення": '#FFA500',
  "Швидка допомога": '#181818',
  "Лабораторія": '#800080',
}

const NON_CATEGORY_COLOR = '#818181'

export const lpus = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_LPU:
      return {
        ...state,
        lpu: action.payload.map((item) => {
          const category = (item?.specialization || '').split(',')[0];

          if (category === undefined) {
            item.icon = NON_CATEGORY_COLOR;
            return item;
          }

          item.icon = SPECIALIZATION_COLORS[category];
          return item;
        }),
      };

    case ActionType.SET_CURRENT_LPU_LIST:
      return {
        ...state,
        currentLpu: action.payload.map((item) => {
          // const category = state.lpuCategories.find((category) => {
          //   if (category.id === "0" && item.category_id === null) {
          //     return true;
          //   }

          //   if (category.id === item.category_id) {
          //     return true;
          //   }

          //   return false;
          // });
          const category = (item?.specialization || '').split(',')[0];

          item.icon = category ? SPECIALIZATION_COLORS[category] : NON_CATEGORY_COLOR;
          return item;
        }),
      };
    // case ActionType.SET_LPU_CATEGORIES:
    //   return {
    //     ...state,
    //     lpuCategories:
    //       action.payload.length > 0
    //         ? [
    //             ...action.payload,
    //             {
    //               id: "-1",
    //               name: "Інше",
    //               icon: noneCategoryIcon,
    //             },
    //           ]
    //         : [],
    //   };
    case ActionType.SET_LPU_CATEGORIES:
      return {
        ...state,
        lpuCategories: action.payload,
      };
    case ActionType.SET_FILTERED_CATEGORIES:
      return {
        ...state,
        filteredCategories: action.payload,
      };
    default:
      return state;
  }
};
