import React, { useEffect, useRef, useState } from "react";
import MapNew from "./map";
import "./map.scss";
import connect from "react-redux/es/connect/connect";
import MobileSaerchLpuList from "./mobileSaerchLpuList";
import MobileFilterLpuList from "./mobileFilterLpuList.jsx";
import { setLpu } from "../../../../actions/lpu/setLpu";
import { setCurrentLpuList } from "../../../../actions/lpu/setCurrentLpuList";
import { setMapFilterState } from "../../../../actions/control/setMapFilterState.js";
import { setFilteredCategories } from "../../../../actions/lpu/setFilteredCategories";
import { addLocation } from "../../../../actions/user/addLocation";
import { lpuFilterByCategory, searchLpuByName } from "../../../../utils/utils";
import filterChecked from "../../../../assets/img/filter_checked.png";
import useOutsideClick from "../../../../hooks/useOutsideClick";
import AppoinDoctor from "../../main/appoinDoctorForm/appoinDoctor"
import MobileMarkersList from "./mobileMarkersList";
import "./map.scss";

let searchValue;

export function MapContainer({
  isMobile = false,
  setLpuCurrentSearchList,
  setMapFilter,
  lpu,
  lpuCategories,
  filteredCategories,
  setCheckedCategories,
  currentLpus,
  setAddLocation,
  userLocation
}) {
  const [activePage, setActivePage] = useState('map')
  const [isSearch, setIsSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [medicalInfo, setMedicalInfo] = useState(null)
  const [isOpenAppointDoctor, setisOpenAppointDoctor] = useState(false)
  const [isFilterDescktopFormOpen, setIsFilterDesktopFormOpen] =
    useState(false);

  const searchInput = useRef();
  const filterFormRef = useRef();

  const onFormClickHandle = () => {
    if (isFilterDescktopFormOpen) {
      filterLpu();
    }

    setIsFilterDesktopFormOpen(!isFilterDescktopFormOpen);
  };

  const onCanselClickHandle = () => {
    setIsSearch(false);
    setIsFilter(false);
    setMapFilter(false);
    setCheckedCategories([])
    setLpuCurrentSearchList(lpu.slice());
  };

  const onSearchClickHandle = (evt) => {
    evt.preventDefault();
    setIsSearch(true);
    setMapFilter(true);
  };

  const onFilterClickHandle = (evt) => {
    evt.preventDefault();
    setIsFilter(true);
    setMapFilter(true);
  };

  const onBackToMapClickHandle = () => {
    filterLpu();
    setIsFilter(false);
    setIsSearch(false);
    setMapFilter(false);
  };

  const onBackToMapClickHandleSearch = () => {
    setIsFilter(false);
    setIsSearch(false);
    setMapFilter(false);
  };

  const onCheckClickHandle = (evt, category) => {
    // console.log({category}, 1)
    // console.log(category.name)
    // let resultArray;
    // console.log(evt.target.checked)
    // if (evt.target.checked) {
    //   resultArray = [...filteredCategories, category.id];
    // }

    // if (!evt.target.checked) {
    //   resultArray = filteredCategories.slice();
    //   resultArray.filter(item => {
    //     console.log({item})
    //     return item.specialization.toLowerCase().includes(category.name.toLowerCase());
    //   });

    //   console.log(resultArray)
    //   // resultArray.splice(resultArray.indexOf(category.id), 1);
    // }

    // setCheckedCategories(resultArray);
    let resultArray;
    if (evt.target.checked) {
      resultArray = [...filteredCategories, category.id];
    }

    if (!evt.target.checked) {
      resultArray = filteredCategories.slice();
      resultArray.splice(resultArray.indexOf(category.id), 1);
    }

    setCheckedCategories(resultArray);
  };

  const onChangeInputHandler = () => {
    searchValue = searchInput.current.value;

    if (searchValue !== "") {
      setLpuCurrentSearchList(searchLpuByName(lpu, searchValue));
    }

    if (searchValue === "") {
      setLpuCurrentSearchList(lpu.slice());
    }
  };

  const openAppoitDoctorPage = () => {
    setisOpenAppointDoctor(true)
    setMapFilter(true)
  }

  const onCloseAppointymentDoctor = () => {
    setisOpenAppointDoctor(false)
    setMapFilter(false)
  }

  const onVisitButtonHandle = (evt, value) => {
    evt.preventDefault()
    setActivePage(value)
  }

  const filterLpu = () => {
    if (lpu && filteredCategories.length === 0) {
      setLpuCurrentSearchList(lpu.slice());
    }

    if (lpu && filteredCategories.length > 0) {
      let all = filteredCategories.find(el => el === '0')
      if (all) {
        return setLpuCurrentSearchList(lpu)
      } else {
        setLpuCurrentSearchList(lpuFilterByCategory(lpu, lpuCategories, filteredCategories));
      }
    }
  };

  useOutsideClick(filterFormRef, () => {
    setIsFilterDesktopFormOpen();
  });

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;
    
        setAddLocation({latitude, longitude})
        console.log("Широта: " + latitude + ", Довгота: " + longitude);
      }
      );
    }
  },[])

  useEffect(() => {
    if (!isMobile) {
      filterLpu();
    }
  }, [filteredCategories]);



  if (isMobile) {
    return (
      <>
        {!isOpenAppointDoctor ?
           ( 
            <>
            <div className="map-container-mobile">
              <div className="map-container-mobile__head">
                <div className="map-container-mobile__head-top-block">
                  <h1 className="map-title-mobile">Карта ЛПУ</h1>
                <div>
                  <button
                    className="map-container-mobile__search-button"
                    onClick={(evt) => onSearchClickHandle(evt)}
                  />
                  <button
                    onClick={(evt) => onFilterClickHandle(evt)}
                    className="map-container-mobile__filter-button map-container-mobile__filter-button--checked"
                  />
                </div>
                </div>
                  <div className="map-container-mobile__page-button-container">
                  <button
                      onClick={(evt) => onVisitButtonHandle(evt, 'map')}
                      className={`map-container-mobile__page-button ${
                        activePage === 'map'
                          ? "map-container-mobile__page-button--active"
                          : ""
                      }`}
                    >
                      Карта
                    </button>
                    <button
                      onClick={(evt) => onVisitButtonHandle(evt, 'list')}
                      className={`map-container-mobile__page-button ${
                        activePage === 'list' 
                        ? "map-container-mobile__page-button--active" 
                        : ""
                      }`}
                    >
                      Список
                    </button>
                  </div>
              </div>
              {activePage === 'map' ? 
              <MapNew isMobile={isMobile} openAppoitDoctorPage={openAppoitDoctorPage} setMedicalInfo={setMedicalInfo}/> : 
              <MobileMarkersList openAppoitDoctorPage={openAppoitDoctorPage} setMedicalInfo={setMedicalInfo}/>}
            </div>
            {isFilter && (
              <div className="map-container-mobile__filter">
                <MobileFilterLpuList
                  onCanselClickHandle={() => onCanselClickHandle()}
                  onBackToMapClickHandle={() => onBackToMapClickHandle()}
                  onCheckClickHandle={(evt, categoryId) =>
                    onCheckClickHandle(evt, categoryId)
                  }
                />
              </div>
            )}
            {isSearch && (
              <div className="map-container-mobile__filter">
                <MobileSaerchLpuList
                  onCanselClickHandle={() => onCanselClickHandle()}
                  onBackToMapClickHandle={() => onBackToMapClickHandleSearch()}
                />
              </div>
            )}
          </>
          ) : ( 
               <AppoinDoctor
            isMobile={true}
            onBack={() => onCloseAppointymentDoctor()}
            medicalInfo={medicalInfo}
            />       
          )
        } 
      </>
    );
  }

  return (
    <>
      <div className="map-container">
        <h1 className="map-title">
          <span>Карта ЛПУ</span>
        </h1>
        <div className="map-tools">
          <form
            ref={filterFormRef}
            className={`map-form ${
              isFilterDescktopFormOpen ? "" : "map-form--closed"
            }`}
          >
            <div
              className="map-form__title"
              onClick={() => onFormClickHandle()}
            >
              Застосувати фільтр
            </div>
            <div className="map-form__input-block">
              {lpuCategories.map((item) => {
                return (
                  <div className="map-form__input-block-item" key={item.id}>
                    <input
                      type="checkbox"
                      id={item.id}
                      onChange={(evt) => onCheckClickHandle(evt, item.id)}
                      checked={
                        filteredCategories.indexOf(item.id) >= 0 ? true : false
                      }
                    />
                    <label htmlFor={item.id}>{item.name}</label>
                  </div>
                );
              })}
            </div>
          </form>
          <div className="map-search-block">
            <input
              ref={searchInput}
              className="map-search"
              type="text"
              placeholder="Пошук ЛПУ"
              id="map-serch"
              onChange={() => onChangeInputHandler()}
            />
            <label className="map-search-label" htmlFor="map-search"></label>
          </div>
        </div>

        {<MapNew isMobile={false} />}
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setLpuCurrentSearchList(lpuList) {
    dispatch(setCurrentLpuList(lpuList));
  },
  setCheckedCategories(categories) {
    dispatch(setFilteredCategories(categories));
  },
  setMapFilter(value) {
    dispatch(setMapFilterState(value));
  },
  setAddLocation(value) {
    dispatch(addLocation(value))
  }
});

const mapStateToProps = (state) => ({
  lpu: state.lpus.lpu,
  lpuCategories: state.lpus.lpuCategories,
  filteredCategories: state.lpus.filteredCategories,
  currentLpus: state.lpus.currentLpu,
  userLocation: state.user.userData.userLocation
});

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
