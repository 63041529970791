import React from "react";
import { FileNameBlock } from "./fileNameBlock";
import { DOCUMENT_TYPES } from "../../../../const/const";

export function FileListBlock({
  onFilesClickHandler,
  filesInformation,
  ticket,
  rowIndex,
  closeFileInformation,
}) {
  const {
    fileOrder,
    filesAct,
    filesCopyInn,
    filesCopyInsurance,
    filesCopyPassport,
    filesMedicalReturn,
    filesPayMentReceipt,
  } = ticket;

  return (
    <>
      {fileOrder.length > 0 && (
        <span
          onClick={() => onFilesClickHandler(DOCUMENT_TYPES.fileOrder)}
          className="refund-list__file-name-block"
        >
          {DOCUMENT_TYPES.fileOrder}({1});
          {filesInformation.fileType === DOCUMENT_TYPES.fileOrder &&
            filesInformation.rowIndex === rowIndex && (
              <FileNameBlock
                filesArray={[fileOrder]}
                closeFileInformation={() => closeFileInformation()}
              />
            )}
        </span>
      )}
      {filesAct.length > 0 && (
        <span
          onClick={() => onFilesClickHandler(DOCUMENT_TYPES.filesAct)}
          className="refund-list__file-name-block"
        >
          {DOCUMENT_TYPES.filesAct}({filesAct.length});
          {filesInformation.fileType === DOCUMENT_TYPES.filesAct &&
            filesInformation.rowIndex === rowIndex && (
              <FileNameBlock
                filesArray={[...filesAct]}
                closeFileInformation={() => closeFileInformation()}
              />
            )}
        </span>
      )}
      {filesCopyInn.length > 0 && (
        <span
          onClick={() => onFilesClickHandler(DOCUMENT_TYPES.filesCopyInn)}
          className="refund-list__file-name-block"
        >
          {DOCUMENT_TYPES.filesCopyInn}({filesCopyInn.length});
          {filesInformation.fileType === DOCUMENT_TYPES.filesCopyInn &&
            filesInformation.rowIndex === rowIndex && (
              <FileNameBlock
                filesArray={[...filesCopyInn]}
                closeFileInformation={() => closeFileInformation()}
              />
            )}
        </span>
      )}
      {filesCopyInsurance.length > 0 && (
        <span
          onClick={() => onFilesClickHandler(DOCUMENT_TYPES.filesCopyInsurance)}
          className="refund-list__file-name-block"
        >
          {DOCUMENT_TYPES.filesCopyInsurance}({filesCopyInsurance.length});
          {filesInformation.fileType === DOCUMENT_TYPES.filesCopyInsurance &&
            filesInformation.rowIndex === rowIndex && (
              <FileNameBlock
                filesArray={[...filesCopyInsurance]}
                closeFileInformation={() => closeFileInformation()}
              />
            )}
        </span>
      )}
      {filesCopyPassport.length > 0 && (
        <span
          onClick={() => onFilesClickHandler(DOCUMENT_TYPES.filesCopyPassport)}
          className="refund-list__file-name-block"
        >
          {DOCUMENT_TYPES.filesCopyPassport}({filesCopyPassport.length});
          {filesInformation.fileType === DOCUMENT_TYPES.filesCopyPassport &&
            filesInformation.rowIndex === rowIndex && (
              <FileNameBlock
                filesArray={[...filesCopyPassport]}
                closeFileInformation={() => closeFileInformation()}
              />
            )}
        </span>
      )}
      {filesMedicalReturn.length > 0 && (
        <span
          onClick={() => onFilesClickHandler(DOCUMENT_TYPES.filesMedicalReturn)}
          className="refund-list__file-name-block"
        >
          {DOCUMENT_TYPES.filesMedicalReturn}({filesMedicalReturn.length});
          {filesInformation.fileType === DOCUMENT_TYPES.filesMedicalReturn &&
            filesInformation.rowIndex === rowIndex && (
              <FileNameBlock
                filesArray={[...filesMedicalReturn]}
                closeFileInformation={() => closeFileInformation()}
              />
            )}
        </span>
      )}
      {filesPayMentReceipt.length > 0 && (
        <span
          onClick={() =>
            onFilesClickHandler(DOCUMENT_TYPES.filesPayMentReceipt)
          }
          className="refund-list__file-name-block"
        >
          {DOCUMENT_TYPES.filesPayMentReceipt}({filesPayMentReceipt.length});
          {filesInformation.fileType === DOCUMENT_TYPES.filesPayMentReceipt &&
            filesInformation.rowIndex === rowIndex && (
              <FileNameBlock
                filesArray={[...filesPayMentReceipt]}
                closeFileInformation={() => closeFileInformation()}
              />
            )}
        </span>
      )}
    </>
  );
}
